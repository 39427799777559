import ReactDOM from 'react-dom/client'
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom'
import ForgotPassword from './forgot_password'
import { HandlerProvider, useHandler } from "./handler_provider"
import './index.css'
import Inquiry from './inquiry'
import ListedProject from './listed_project'
import ListedProjects from './listed_projects'
import Login from './login'
import NotFound from './not_found'
import OwnedBookings from './owned_bookings'
import OwnedInquiries from './owned_inquiries'
import OwnedProject from './owned_project'
import OwnedProjects from './owned_projects'
import ProjectSubmission from './project_submission'
import Register from './register'
import reportWebVitals from "./reportWebVitals"
import Root from './root'
import { useEffect, useState } from 'react'
import EligibilityCheckSubmission from './eligibility_check_submission'
import EligibilityCheckSubmissionBak from './eligibility_check_submission_bak'
import OwnedEligibilityChecks from './eligibility_check_submission/owned'

function React(handler) {
  const router = createBrowserRouter([{
    path: '/',
    element: <Root />,
    children: [
      { index: true, element: <ListedProjects /> },
      { path: '/listed-projects/:id', element: <ListedProject /> },
      {
        element: <Public />,
        children: [
          { path: '/login', element: <Login /> },
          { path: '/register', element: <Register /> },
          { path: '/forgot-password', element: <ForgotPassword /> },
        ],
      },
      {
        element: <Private />,
        children: [
          {
            path: '/inquiry',
            element: <Inquiry />,
          },
          {
            path: '/owned-bookings',
            element: <OwnedBookings />,
          },
          {
            path: '/owned-inquiries',
            element: <OwnedInquiries />,
          },
          {
            path: '/owned-projects',
            element: <OwnedProjects />,
          },
          {
            path: '/owned-projects/:id',
            element: <OwnedProject />,
          },
          {
            path: '/project-submission',
            element: <ProjectSubmission />,
          },
          {
            path: '/eligibility-check-submission/:id?',
            element: <EligibilityCheckSubmission />,
          },
          {
            path: '/eligibility-check-submission-bak',
            element: <EligibilityCheckSubmissionBak />,
          },
          {
            path: '/eligibility-check-submission/owned',
            element: <OwnedEligibilityChecks />,
          },
        ],
      },
      { path: '*', element: <NotFound /> }
    ]
  }])


  async function start() {
    ReactDOM
      .createRoot(document.getElementById('root'))
      .render(<HandlerProvider handler={handler} >
        <RouterProvider router={router} />
      </HandlerProvider>)

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals()
  }

  return { start }
}

function Public() {
  const handler = useHandler()
  const [token, setToken] = useState('waiting')
  useEffect(() => {
    handler.subToToken(setToken)
    return () => handler.unsubFromToken(setToken)
  }, [handler])

  if (token === 'waiting') {
    return <></>
  } else if (token) {
    return <Navigate to="/" replace={true} />
  } else {
    return <Outlet />
  }
}

function Private() {
  const handler = useHandler()
  const [token, setToken] = useState('waiting')
  useEffect(() => {
    handler.subToToken(setToken)
    return () => handler.unsubFromToken(setToken)
  }, [handler])

  if (token === 'waiting') {
    return <></>
  } else if (!token) {
    window.location.assign([
      process.env.REACT_APP_ACCOUNT_URL,
      '/login?',
      `redirect_=${window.location.href}`,
    ].join(''))

    return <></>
  } else {
    return <Outlet />
  }
}

export default React