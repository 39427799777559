import {
  createContext,
  useContext
} from 'react'

const context = createContext({
  requestLanguageChange: (request) => { },
  requestUserRegistration: (request) => { },
  requestUserTokenRenewal: (request) => { },
  requestUserOtpRenewal: (request) => { },
  requestUserPasswordRenewal: (request) => { },
  requestUserInfoLookup: (request) => { },
  requestCreditTypesLookup: (request) => { },
  requestSdgsLookup: (request) => { },
  requestMechanismsLookup: (request) => { },
  requestSectorsLookup: (request) => { },
  requestProjectAppealSubmission: (request) => { },
  requestOwnedProjectAppealSummariesLookup: (request) => { },
  requestOwnedProjectAppealLookup: (request) => { },
  requestQuestionnaireSubmission: (request) => { },
  requestProjectSubmission: (request) => { },
  requestOwnedProjectSummariesLookup: (request) => { },
  requestOwnedProjectLookup: (request) => { },
  requestListedProjectSummariesLookup: (request) => { },
  requestListedProjectLookup: (request) => { },
  requestProjectBooking: (request) => { },
  requestOwnedBookingSummariesLookup: (request) => { },
  requestProjectFunding: (request) => { },
  requestEligibilityCheckDrafting: (request) => { },
  requestEligibilityCheckSubmission: (request) => { },
  requestOwnedEligibilityCheckSummariesLookup: (request) => { },
  requestOwnedEligibilityCheckDetailLookup: (request) => { },
  requestUserTokenDisposal: (request) => { },
  subToLoading: (receiver) => { },
  unsubFromLoading: (receiver) => { },
  subToFailure: (receiver) => { },
  unsubFromFailure: (receiver) => { },
  subToFailureAssociation: (receiver) => { },
  unsubFromFailureAssociation: (receiver) => { },
  subToSuccess: (receiver) => { },
  unsubFromSuccess: (receiver) => { },
  subToLexicon: (receiver) => { },
  unsubFromLexicon: (receiver) => { },
  subToLanguage: (receiver) => { },
  unsubFromLanguage: (receiver) => { },
  subToGraph: (receiver) => { },
  unsubFromGraph: (receiver) => { },
  subToRedirect: (receiver) => { },
  unsubFromRedirect: (receiver) => { },
  subToOtpRenewalResult: (receiver) => { },
  unsubFromOtpRenewalResult: (receiver) => { },
  subToToken: (receiver) => { },
  unsubFromToken: (receiver) => { },
  subToUserInfo: (receiver) => { },
  unsubFromUserInfo: (receiver) => { },
  subToCreditTypes: (receiver) => { },
  unsubFromCreditTypes: (receiver) => { },
  subToSdgs: (receiver) => { },
  unsubFromSdgs: (receiver) => { },
  subToMechanisms: (receiver) => { },
  unsubFromMechanisms: (receiver) => { },
  subToSectors: (receiver) => { },
  unsubFromSectors: (receiver) => { },
  subToOwnedProjectAppealSummaries: (receiver) => { },
  unsubFromOwnedProjectAppealSummaries: (receiver) => { },
  subToOwnedProjectAppeal: (receiver) => { },
  unsubFromOwnedProjectAppeal: (receiver) => { },
  subToProjectSubmissionResult: (receiver) => { },
  unsubFromProjectSubmissionResult: (receiver) => { },
  subToOwnedProjectSummaries: (receiver) => { },
  unsubFromOwnedProjectSummaries: (receiver) => { },
  subToOwnedProject: (receiver) => { },
  unsubFromOwnedProject: (receiver) => { },
  subToListedProjectSummaries: (receiver) => { },
  unsubFromListedProjectSummaries: (receiver) => { },
  subToListedProject: (receiver) => { },
  unsubFromListedProject: (receiver) => { },
  subToOwnedBookingSummaries: (receiver) => { },
  unsubFromOwnedBookingSummaries: (receiver) => { },
  subToOwnedEligibilityCheckSummaries: (receiver) => { },
  unsubFromOwnedEligibilityCheckSummaries: (receiver) => { },
  subToOwnedEligibilityCheckDetail: (receiver) => { },
  unsubFromOwnedEligibilityCheckDetail: (receiver) => { },
})

export function HandlerProvider({ handler, children }) {
  return <context.Provider value={handler}>
    {children}
  </context.Provider>
}

export const HandlerConsumer = context.Consumer

export function useHandler() {
  return useContext(context)
}
