// Import Library
import React from 'react';

// Import Assets
import DarkTruClimateLogo from "../react/images/dark-truclimate.svg"

export default function Footer() {
    return (
        <footer className="py-4 px-11 md:px-14 bg-[#4C638C] flex flex-col gap-5">
            <div className="flex flex-col lg:flex-row lg:items-center gap-4">
                <div to="/" className="flex-1 flex flex-row items-center">
                    <img className="max-h-6" src={DarkTruClimateLogo} height="25" alt="logo dark" />
                </div>
                <ul className="list-inline mb-0 footer-social-link">
                    <li className="list-inline-item">
                        <a
                            className="avatar-xs d-block"
                            href="https://linkedin.com/company/truclimate"
                            target="_blank" rel="noreferrer"
                        >
                            <div className="avatar-title rounded-circle">
                                <i
                                    className="ri-linkedin-fill"
                                    style={{ color: "lightGray" }}
                                ></i>
                            </div>
                        </a>
                    </li>
                    <li className="list-inline-item">
                        <a
                            className="avatar-xs d-block"
                            href="https://instagram.com/truclimate"
                            target="_blank" rel="noreferrer"
                        >
                            <div className="avatar-title rounded-circle">
                                <i
                                    className="ri-instagram-fill"
                                    style={{ color: "lightGray" }}
                                ></i>
                            </div>
                        </a>
                    </li>
                    <li className="list-inline-item">
                        <a
                            className="avatar-xs d-block"
                            href="https://wa.me/+6281212019666"
                            target="_blank" rel="noreferrer"
                        >
                            <div className="avatar-title rounded-circle">
                                <i
                                    className="ri-whatsapp-fill"
                                    style={{ color: "lightGray" }}
                                ></i>
                            </div>
                        </a>
                    </li>
                    <li className="list-inline-item">
                        <a
                            className="avatar-xs d-block"
                            href="mailto:contact@truclimate.earth"
                            target="_blank" rel="noreferrer"
                        >
                            <div className="avatar-title rounded-circle">
                                <i
                                    className="ri-mail-line"
                                    style={{ color: "lightGray" }}
                                ></i>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            <p className="copy-rights mb-0 text-gray-300">
                &copy;&nbsp;{new Date().getFullYear()} TruClimate
            </p>
        </footer >
    )
}
