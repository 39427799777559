import { LockOutlined, MailOutlined, ShopOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Link } from "react-router-dom";
import { useHandler } from "./handler_provider";
import logo from "./images/dark-logo.webp";

function Register() {
  const handler = useHandler()
  const [lexicon, setLexicon] = useState({})
  useEffect(() => {
    handler.subToLexicon(setLexicon)
    return () => handler.unsubFromLexicon(setLexicon)
  }, [handler])
  const [form] = Form.useForm();
  const [phone, setPhone] = useState({
    phoneCode: '62',
    phoneNumber: '',
  })
  const handlePhoneNumberChange = (code, number) => {
    setPhone({
      phoneCode: code,
      phoneNumber: number,
    })
  }

  return <div className="auth-page-wrapper pt-5">
    <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div className="bg-overlay"></div>

      <div className="shape">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1440 120"
        >
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
    </div>

    <div className="auth-page-content">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <Link to="/" className="d-inline-block auth-logo">
                  <img className="max-h-5" src={logo} alt="" height="20" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="card mt-4">
              <div className="card-body p-4">
                <div className="text-center mt-2">
                  <h5 className="text-primary text-base">
                    {lexicon.welcome_to_tru_carbon}
                  </h5>
                  <p className="text-muted text-base">
                    {lexicon.create_new_account}
                  </p>
                </div>
                <div className="p-2 mt-4">
                  <Form
                    layout="vertical"
                    form={form}
                    name="register"
                    onFinish={(values) => {
                      handler.requestUserRegistration({
                        email: values.email,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        phone: `+${phone.phoneCode}${phone.phoneNumber}`,
                        companyName: values.companyName,
                        password: values.password,
                      })
                    }}
                    initialValues={{
                      // residence: ["zhejiang", "hangzhou", "xihu"],
                      // prefix: "86",
                      // phone: {
                      // 	countryCode: `${countryCode}`
                      // }
                    }}
                    style={{
                      maxWidth: 600,
                    }}
                    scrollToFirstError
                  >
                    <Form.Item
                      name="email"
                      label={lexicon.email}
                      rules={[
                        {
                          type: "email",
                          message: "Invalid Email",
                        },
                        {
                          required: true,
                          message: "Please enter your Email",
                        },
                      ]}
                    >
                      <Input
                        prefix={
                          <MailOutlined
                            style={{ color: "lightGray" }}
                            className="site-form-item-icon"
                          />
                        }
                        placeholder={lexicon.email}
                      />
                    </Form.Item>
                    <Form.Item
                      name="firstName"
                      label={lexicon.first_name}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your First Name",
                        },
                      ]}
                    >
                      <Input
                        prefix={
                          <UserOutlined
                            style={{ color: "lightGray" }}
                            className="site-form-item-icon"
                          />
                        }
                        placeholder={lexicon.first_name}
                      />
                    </Form.Item>
                    <Form.Item
                      name="lastName"
                      label={lexicon.last_name}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Last Name",
                        },
                      ]}
                    >
                      <Input
                        prefix={
                          <UserOutlined
                            style={{ color: "lightGray" }}
                            className="site-form-item-icon"
                          />
                        }
                        placeholder={lexicon.last_name}
                      />
                    </Form.Item>
                    <Form.Item
                      name='phone'
                      label={lexicon.phone_number}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your Phone Number',
                        },
                      ]}
                    >
                      <PhoneInput
                        id="phone"
                        inputProps={{
                          name: 'phone',
                          required: true,
                          autoFocus: true
                        }}
                        inputClass="w-full"
                        inputStyle={{ width: '100%' }}
                        prefix={''}
                        country={'id'}
                        placeholder={lexicon.phone_number}
                        type='text'
                        disabled={false}
                        autoFormat={false}
                        enableSearch={false}
                        onChange={(value, data) => {
                          handlePhoneNumberChange(
                            data.dialCode,
                            (
                              value.length && value.substring(0, 1) === '0' ?
                                value
                                :
                                value.replace(/[^0-9]+/g, '').slice(data.dialCode.length)
                            )
                          )
                        }
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name="companyName"
                      label={lexicon.company_name}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Company Name",
                        },
                      ]}
                    >
                      <Input
                        prefix={
                          <ShopOutlined
                            style={{ color: "lightGray" }}
                            className="site-form-item-icon"
                          />
                        }
                        placeholder={lexicon.company_name}
                      />
                    </Form.Item>

                    <Form.Item
                      name="password"
                      label={lexicon.password}
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                        {
                          min: 6,
                          message: "Please input your password more tha 6 characters",
                        },
                      ]}
                      hasFeedback
                    >

                      <Input.Password
                        prefix={
                          <LockOutlined
                            style={{ color: "lightGray" }}
                            className="site-form-item-icon"
                          />
                        }
                        type="password"
                        placeholder={lexicon.password}
                      />
                    </Form.Item>

                    <Form.Item>
                      <Button className="bg-[#3EB6AE]" type="primary" htmlType="submit" block >
                      {lexicon.sign_up}
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>

            <div className="mt-4 text-center">
              <p className="mb-0">
                {lexicon.already_have_an_account}? {' '}
                <Link
                  to={"/login"}
                  className="fw-semibold text-primary text-decoration-underline"
                >
                  {lexicon.sign_in}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default Register