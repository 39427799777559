import { InfoCircleOutlined } from "@ant-design/icons";
import InputText from "./input-text";

export default function InputCoordinate({
  getStatusEligibility,
  getLanguage,
  onChange,
  index,
  item,
  ...rest
}) {
  const decimalDegrees = item?.value
  const [
    degrees,
    minutes,
    seconds,
  ] = decimalDegreesToDegreesMinutesSecondsConversion(
    decimalDegrees
  )

  return <div
    {...rest}
    className={[
      'flex',
      'flex-col',
      'gap-1',
    ].join(' ')}
    key={index}
  >
    <div
      className={[
        'flex',
        'gap-1',
        'items-center',
        'relative',
      ].join(' ')}
    >
      <InputText
        {...item}
        disabled={getStatusEligibility?.toLowerCase() === 'submitted'}
        label={item?.label?.[getLanguage]}
        name={item?.name}
        onChange={onChange}
        required={item?.required}
        size='large'
        type='number'
        value={decimalDegrees}
        placeholder='Decimal Degrees'
      />
      <div
        className={[
          'absolute',
          'right-3',
          'h-3',
          'w-3',
          'flex',
          'justify-center',
          'items-center',
        ].join(' ')}
      >
        <span
          className={[
            'daisy-tooltip',
            index % 2 === 0 ? '' : 'daisy-tooltip-left',
            'whitespace-pre-line',
          ].join(' ')}
          data-tip={
            getLanguage === 'en-US'
              ? `The number must be between ${item?.min} and ${item?.max}`
              : `Angka harus berada antara ${item?.min} dan ${item?.max}`
          }
        >
          <InfoCircleOutlined
            className={[
              'text-lg',
              'text-[#4C638C]/60',
            ].join(' ')}
          />
        </span>
      </div>
    </div>
    <div
      className={[
        'flex',
        'gap-1',
      ].join(' ')}
    >
      <div
        className={[
          'flex-1',
          'flex',
          'gap-1',
          'items-center',
          'relative',
        ].join(' ')}
      >
        <InputText
          {...item}
          disabled={getStatusEligibility?.toLowerCase() === 'submitted'}
          label={item?.label?.[getLanguage]}
          name={item?.name}
          onChange={event => {
            if (isNaN(Number(event.target.value))) {
              onChange(event)
            } else {
              event.target.value = degreesMinutesSecondsToDecimalDegreesConversion(
                Number(event.target.value),
                minutes,
                seconds,
              )
            }
            onChange(event)
          }}
          required={item?.required}
          size='large'
          type={'number'}
          value={degrees}
          placeholder='Degrees'
        />
        <div
          className={[
            'absolute',
            'right-3',
            'h-3',
            'w-3',
            'flex',
            'justify-center',
            'items-center',
          ].join(' ')}
        >
          <span
            className="daisy-tooltip whitespace-pre-line"
            data-tip={
              getLanguage === 'en-US'
                ? `The number must be between ${item?.min} and ${item?.max}`
                : `Angka harus berada antara ${item?.min} dan ${item?.max}`
            }
          >
            <InfoCircleOutlined
              className={[
                'text-lg',
                'text-[#4C638C]/60',
              ].join(' ')}
            />
          </span>
        </div>
      </div>
      <div className={[
        'flex-1',
        'flex',
        'gap-1',
        'items-center',
        'relative',
      ].join(' ')}>
        <InputText
          min={0}
          max={60}
          disabled={getStatusEligibility?.toLowerCase() === 'submitted'}
          label={item?.label?.[getLanguage]}
          name={item?.name}
          onChange={event => {
            if (isNaN(Number(event.target.value))) {
              onChange(event)
            } else {
              event.target.value = degreesMinutesSecondsToDecimalDegreesConversion(
                degrees,
                Number(event.target.value),
                seconds,
              )
              if (
                event.target.value >= item.min &&
                event.target.value <= item.max
              ) {
                onChange(event)
              }
            }
          }}
          required={item?.required}
          size='large'
          type={'number'}
          value={minutes}
          placeholder='Minutes'
        />
        <div
          className={[
            'absolute',
            'right-3',
            'h-3',
            'w-3',
            'flex',
            'justify-center',
            'items-center',
          ].join(' ')}
        >
          <span
            className="daisy-tooltip whitespace-pre-line"
            data-tip={
              getLanguage === 'en-US'
                ? `The number must be between ${0} and ${60}`
                : `Angka harus berada antara ${0} dan ${60}`
            }
          >
            <InfoCircleOutlined
              className={[
                'text-lg',
                'text-[#4C638C]/60',
              ].join(' ')}
            />
          </span>
        </div>
      </div>
      <div className={[
        'flex-1',
        'flex',
        'gap-1',
        'items-center',
        'relative',
      ].join(' ')}>
        <InputText
          min={0}
          max={60}
          disabled={getStatusEligibility?.toLowerCase() === 'submitted'}
          label={item?.label?.[getLanguage]}
          name={item?.name}
          onChange={event => {
            if (isNaN(Number(event.target.value))) {
              onChange(event)
            } else {
              event.target.value = degreesMinutesSecondsToDecimalDegreesConversion(
                degrees,
                minutes,
                Number(event.target.value),
              )
              if (
                event.target.value >= item.min &&
                event.target.value <= item.max
              ) {
                onChange(event)
              }
            }
          }}
          required={item?.required}
          size='large'
          type={'number'}
          value={seconds}
          placeholder='Seconds'
        />
        <div
          className={[
            'absolute',
            'right-3',
            'h-3',
            'w-3',
            'flex',
            'justify-center',
            'items-center',
          ].join(' ')}
        >
          <span
            className={[
              'daisy-tooltip',
              index % 2 === 0 ? '' : 'daisy-tooltip-left',
              'whitespace-pre-line',
            ].join(' ')}
            data-tip={
              getLanguage === 'en-US'
                ? `The number must be between ${0} and ${60}`
                : `Angka harus berada antara ${0} dan ${60}`
            }
          >
            <InfoCircleOutlined
              className={[
                'text-lg',
                'text-[#4C638C]/60',
              ].join(' ')}
            />
          </span>
        </div>
      </div>
    </div>
  </div>
}

function decimalDegreesToDegreesMinutesSecondsConversion(decimalDegrees) {
  if (typeof decimalDegrees !== 'number') {
    return [null, null, null]
  } else {
    let degrees = Math.trunc(decimalDegrees)
    let minutes = Math.trunc((decimalDegrees - degrees) * 60)
    let seconds = Math.round((((decimalDegrees - degrees) * 60) - minutes) * 60)
    if (minutes === 60) {
      degrees = degrees + 1
      minutes = 0
    }
    if (seconds === 60) {
      minutes = minutes + 1
      seconds = 0
    }
    return [degrees, minutes, seconds]
  }
}

function degreesMinutesSecondsToDecimalDegreesConversion(
  degrees,
  minutes,
  seconds,
) {
  if (
    typeof degrees !== 'number' ||
    typeof minutes !== 'number' ||
    typeof seconds !== 'number'
  ) {
    return null
  } else {
    return degrees + (minutes / 60) + (seconds / 3600)
  }
}