import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHandler } from "../../handler_provider";
import Layout from "../../layout";
import backgroundGraphicsCard from '../../background_graphics_1.svg'
import backgroundGraphics from '../../background_graphics_0.svg'
import { BsPlus } from "react-icons/bs";

export default function Owned() {

  const [getLanguage, setLanguage] = useState('en-US')

  const [getLexicon, setLexicon] = useState({});
  const [getListEligibility, setListEligibility] = useState(null);

  const handler = useHandler();

  useEffect(() => {
    handler.subToLanguage(setLanguage);
    handler.subToLexicon(setLexicon);

    return () => {
      handler.unsubFromLanguage(setLanguage);
      handler.unsubFromLexicon(setLexicon);
    }
  }, [handler])

  useEffect(() => {
    handler.requestOwnedEligibilityCheckSummariesLookup()

    handler.subToOwnedEligibilityCheckSummaries(setListEligibility)

    return () => handler.unsubFromOwnedEligibilityCheckSummaries(setListEligibility)
  }, [handler])

  if (!getListEligibility) {
    return <></>
  } else {
    return <Layout>
      <main className="bg-[#F9FAFC]">
        <section className='min-h-[calc(100vh-164px)] mx-auto overflow-hidden px-14 py-8 relative w-full'>
          <img src={backgroundGraphics} alt="Background Top" className="absolute left-0 top-0 opacity-10 z-[0]" height={150} width={300} />
          <img src={backgroundGraphics} alt="Background Bottom" className="absolute right-0 bottom-0 opacity-10 rotate-180 z-[0]" height={150} width={300} />

          <div className="flex items-start justify-between relative w-full">
            <h1 className="font-semibold">{getLexicon?.['Track Eligibility']}</h1>
            <Link to={'/eligibility-check-submission'} className="flex items-center gap-1 font-bold text-[#4cdfd5]"><BsPlus size={24} /> {getLexicon?.['Create New']}</Link>
          </div>
          <div className={`${getListEligibility?.length > 0 ? 'grid lg:grid-cols-2 xl:grid-cols-3 gap-8' : ''} mt-4 relative`}>
            {getListEligibility?.length > 0 ?
              getListEligibility.map((item, index) => {
                const field = item?.document;

                return (
                  <Link key={index} to={`/eligibility-check-submission/${item?.id}`} className="bg-white border border-neutral duration-300 grid grid-cols-2 min-w-[384px] relative rounded-xl shadow-md transition-all w-full hover:shadow-xl hover:-translate-y-1">
                    <img src={backgroundGraphicsCard} alt="Background Card" className="absolute left-0 top-0 opacity-[0.015] z-[0]" width={384} />
                    <header className="border-b border-black col-span-2 p-4">
                      <p className="font-semibold">#{item?.id}</p>
                    </header>
                    {field?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={`border-b ${index % 2 === 0 ? '' : 'border-l'} border-black flex flex-col gap-2 p-3 relative`}
                        >
                          <p className="font-medium text-neutral-400 text-xs truncate">
                            {item?.label?.[getLanguage]}
                          </p>
                          <p className="text-lg truncate">
                            {item.value?.label
                              ? item.value.label[getLanguage]
                              : item.value || 'n/a'}
                          </p>
                        </div>
                      )
                    })}
                    <footer className="col-span-2 p-4">
                      <p className="font-semibold text-end">{item?.status}</p>
                    </footer>
                  </Link>
                )
              })
              :
              <div className="bg-[#E2E5ED] border-[#C6CADC] col-span-3 p-2 rounded-lg w-fit">
                {
                  getLanguage === 'id-ID'
                    ? 'Anda Belum Pernah Mengajukan Pemeriksaan Kelayakan!'
                    : 'You Haven\'t Submit Any Eligibility Checks!'
                } &nbsp;
                <Link to="/eligibility-check-submission" className="font-bold text-[#303D67]">
                  {
                    getLanguage === 'id-ID'
                      ? 'Klik Disini'
                      : 'Click Here'
                  }
                </Link>
                &nbsp; {
                  getLanguage === 'id-ID'
                    ? 'Untuk Mengajukan'
                    : 'To Submit'
                }.
              </div>
            }
          </div>
        </section>
      </main>
    </Layout >
  }
}
