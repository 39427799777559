import { mdiClipboardTextClockOutline, mdiInformation, mdiMapMarkerOutline, mdiNumeric1Circle, mdiNumeric2CircleOutline, mdiNumeric3CircleOutline, mdiNumeric4CircleOutline } from "@mdi/js";
import Icon from '@mdi/react';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHandler } from "./handler_provider";
import Layout from "./layout";
import NotFound from "./not_found";

function OwnedProject() {
  const handler = useHandler()
  const [lexicon, setLexicon] = useState('waiting')
  useEffect(() => {
    handler.subToLexicon(setLexicon)
    return () => handler.unsubFromLexicon(setLexicon)
  }, [handler])
  const coalesceFromLexicon = (key) => {
    key = key.trim()
      .replaceAll(' ', '_')
      .replaceAll(/\W/g, '')
      .toLowerCase()
    return lexicon[key] ?? key
  }
  const [ownedProject, setOwnedProject] = useState('waiting')
  const params = useParams()
  useEffect(() => {
    handler.requestOwnedProjectLookup({ id: parseInt(params.id) })
    handler.subToOwnedProject(setOwnedProject)
    return () => {
      handler.unsubFromOwnedProject(setOwnedProject)
    }
  }, [handler, params])
  useEffect(() => {
    if (ownedProject !== 'waiting' && ownedProject) {
      const navbar = document.getElementById('navbar')
      const subNavbar = document.getElementById('sub-navbar')
      const observer = new ResizeObserver(([navbar]) => {
        requestAnimationFrame(() => {
          subNavbar.style.top = `${navbar.target.offsetHeight - 0.9}px`
        })
      })
      observer.observe(navbar)
      return () => observer.unobserve(navbar)
    }
  }, [ownedProject])

  if (
    lexicon === 'waiting' ||
    ownedProject === 'waiting'
  ) {
    return <></>
  } else if (!ownedProject) {
    return <NotFound />
  } else {
    return <Layout>
      <div
        id="sub-navbar"
        className={[
          'flex',
          'flex-col',
          'gap-2',
          'px-14',
          'py-6',
          'sticky',
          'bg-white',
          'z-10',
        ].join(' ')}
      >
        <div className="font-semibold text-3xl text-black bg-white">
          {ownedProject.name}
        </div>
        <div className="flex flex-row items-center">
          <Icon color="#4C638C" path={mdiMapMarkerOutline} size={1} />
          <div className="flex flex-row text-lg text-[#4C638C]">
            {ownedProject.location}
          </div>
        </div>
      </div>
      <div className="min-h-screen flex flex-col font-sans bg-[#F3F3F9]">
        <div className={[
          'flex',
          'flex-col-reverse',
          'lg:flex-row',
          'gap-3',
          'px-4',
          'lg:px-14',
          'py-8',
        ].join(' ')}>
          <div className={[
            'bg-white',
            'flex-1',
            'border',
            'shadow-md',
            'rounded-md',
            'p-3',
            'flex',
            'flex-col',
            'lg:flex-row',
            'gap-3',
          ].join(' ')}>
            <div className="flex-1 flex flex-col gap-3">
              <div className={[
                'bg-slate-300',
                'aspect-[1/0.7]',
                'rounded-md',
                'flex',
              ].join(' ')}>
                {ownedProject.image && <img
                  alt=""
                  src={`data:image/jpeg;base64,${ownedProject.image}`}
                  className="flex-1 rounded-md object-cover"
                />}
              </div>
              <div className="flex flex-col gap-1">
                <div className="text-lg font-medium">
                  {lexicon.description}
                </div>
                <div className="whitespace-pre-line">
                  {ownedProject.description}
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-col gap-3">
              <div className="flex flex-col gap-3">
                <div className="flex flex-col">
                  <div className="text-lg font-medium">
                    {lexicon.project_phase}
                  </div>
                  <div className={[
                    'flex',
                    'flex-rows',
                    'bg-[#4C638C]',
                    'text-white',
                    'rounded-lg',
                    'p-4',
                    'text-center',
                  ].join(' ')}>
                    <div className="flex justify-center">
                      <Icon path={mdiNumeric1Circle} size={1.2} />
                    </div>
                    <div className="flex-1 flex items-center p-2">
                      <hr className="flex-1 border-white opacity-100 border-2" />
                    </div>
                    <div className="flex justify-center">
                      <Icon path={mdiNumeric2CircleOutline} size={1.2} />
                    </div>
                    <div className="flex-1 flex items-center p-2">
                      <hr className="flex-1 border-white opacity-100 border-2" />
                    </div>
                    <div className="flex justify-center">
                      <Icon path={mdiNumeric3CircleOutline} size={1.2} />
                    </div>
                    <div className="flex-1 flex items-center p-2">
                      <hr className="flex-1 border-white opacity-100 border-2" />
                    </div>
                    <div className="flex justify-center">
                      <Icon path={mdiNumeric4CircleOutline} size={1.2} />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="text-lg font-medium">
                    {lexicon.overview}
                  </div>
                  <div className="flex flex-col gap-3">
                    <div className="flex-1 flex flex-col items-stretch bg-[#C7ECE7] border-2 border-[#4C638C] rounded-md">
                      <div className="flex justify-between gap-1 items-center border-b-2 border-[#4C638C] p-2">
                        <div className="text-[#4C638C]">
                          {lexicon.available}
                        </div>
                        <Icon
                          className="bg-[#4C638C] rounded-full"
                          path={mdiInformation}
                          size={1}
                          color={'white'}
                        />
                      </div>
                      <div className="flex gap-1 items-center p-2">
                        <div className="font-medium text-lg">
                          {(
                            ownedProject.potentialOffset -
                            (ownedProject.potentialOffset * 0.2)
                          ).toLocaleString()}
                        </div>
                        <div className="text-[#4C638C]">
                          tCO<sub>2</sub>e
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col lg:flex-row gap-3">
                      <div className="flex-1 flex flex-col items-stretch bg-[#C7ECE7] border-2 border-[#4C638C] rounded-md">
                        <div className="flex justify-between gap-1 items-center border-b-2 border-[#4C638C] p-2">
                          <div className="text-[#4C638C]">
                            {lexicon.ndc_reserved}
                          </div>
                          <Icon
                            className="bg-[#4C638C] rounded-full"
                            path={mdiInformation}
                            size={1}
                            color={'white'}
                          />
                        </div>
                        <div className="flex gap-1 items-center p-2">
                          <div className="font-medium text-lg">
                            {(
                              ownedProject.potentialOffset * 0.2
                            ).toLocaleString()}
                          </div>
                          <div className="text-[#4C638C]">
                            tCO<sub>2</sub>e
                          </div>
                        </div>
                      </div>
                      <div className="flex-1 flex flex-col items-stretch bg-[#C7ECE7] border-2 border-[#4C638C] rounded-md">
                        <div className="flex justify-between gap-1 items-center border-b-2 border-[#4C638C] p-2">
                          <div className="text-[#4C638C]">
                            {lexicon.booked}
                          </div>
                          <Icon
                            className="bg-[#4C638C] rounded-full"
                            path={mdiInformation}
                            size={1}
                            color={'white'}
                          />
                        </div>
                        <div className="flex gap-1 items-center p-2">
                          <div className="font-medium text-lg">
                            {(
                              // Object.values(ownedProject.bookings).reduce((prev, current) => prev + current, 0)
                              10000000
                            ).toLocaleString()}
                          </div>
                          <div className="text-[#4C638C]">
                            tCO<sub>2</sub>e
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="text-lg font-medium">
                    {lexicon.profile}
                  </div>
                  <div className="flex gap-1">
                    <Icon path={mdiClipboardTextClockOutline} size={1} />
                    <div>{ownedProject.year}</div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-lg font-medium">
                  {lexicon.project_data}
                </div>
                <div className="flex gap-1">
                  <div className="font-semibold">
                    {lexicon.credits}:
                  </div>
                  <div>
                    {ownedProject.potentialOffset.toLocaleString()} Tonnes
                  </div>
                </div>
                {Object.entries(ownedProject.extra).map(([key, value]) => {
                  return <div key={key} className="flex gap-1">
                    <div className="font-semibold capitalize">
                      {coalesceFromLexicon(key)}:
                    </div>
                    <div className="capitalize">
                      {value}
                    </div>
                  </div>
                })}
              </div>
              <div className="flex flex-col gap-1">
                {ownedProject.sdgs && <>
                  <div className="text-lg font-medium">
                    {lexicon.sustainable_development_goals}
                  </div>
                  <div className="flex flex-row gap-2">
                    {ownedProject.sdgs.split(',').map((value, index) => {
                      value = parseInt(value)
                      return <div key={index} className={[
                        'bg-slate-300',
                        'flex',
                        'flex-row',
                        'justify-center',
                        'items-center',
                        'rounded-md',
                        'h-16',
                        'aspect-square',
                      ].join(' ')}>
                        <img
                          alt=""
                          src={`/assets/sdgs/${value}.png`}
                          className="object-cover"
                        />
                      </div>
                    })}
                  </div>
                </>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout >
  }
}

const sdgs = [
  'No Poverty',
  'Zero Hunger',
  'Good Health And Well-Being',
  'Quality Education',
  'Gender Equality',
  'Clean Water And Sanitation',
  'Affordable And Clean Energy',
  'Decent Work And Economic Growth',
  'Industry, Innovation And Infrastructure',
  'Reduced Inequalities',
  'Sustainable Cities And Communities',
  'Responsible Consumption And Production',
  'Climate Action',
  'Life Below Water',
  'Life On Land',
  'Peace, Justice And Strong Institutions',
  'Partnerships For The Goals',
]

export default OwnedProject