const defaultDetailEligibility = [
  {
    label: {
      "en-US": "Company Name",
      "id-ID": "Nama Perusahaan"
    },
    name: 'companyName',
    required: true,
    step: 1,
    summary: true,
    type: 'text',
    value: '',
    visibility: true
  },
  {
    label: {
      "en-US": "Company Address",
      "id-ID": "Alamat Perusahaan"
    },
    name: 'companyAddress',
    required: true,
    step: 1,
    summary: true,
    type: 'text',
    value: '',
    visibility: true,
  },
  {
    label: {
      "en-US": "Contact Person Address",
      "id-ID": "Alamat Narahubung"
    },
    name: 'contactPersonAddress',
    required: true,
    step: 1,
    summary: true,
    type: 'text',
    value: '',
    visibility: true,
  },
  {
    label: {
      "en-US": "Contact Person Email",
      "id-ID": "Email Narahubung"
    },
    name: 'contactPersonEmail',
    required: true,
    step: 1,
    summary: true,
    type: 'text',
    value: '',
    visibility: true,
  },
  {
    label: {
      "en-US": "Contact Person Name",
      "id-ID": "Nama Narahubung"
    },
    name: 'contactPersonName',
    required: true,
    step: 1,
    summary: true,
    type: 'text',
    value: '',
    visibility: true,
  },
  {
    label: {
      "en-US": "Role",
      "id-ID": "Peran"
    },
    name: 'role',
    options: [
      {
        value: 'land_owner',
        label: {
          'en-US': 'Landowner',
          'id-ID': 'Pemilik Tanah',
        },
      },
      {
        value: 'project_proponent',
        label: {
          'en-US': 'Project Proponent',
          'id-ID': 'Pengembang Proyek',
        },
      },
      {
        value: 'jv',
        label: {
          'en-US': 'JV',
          'id-ID': 'JV',
        },
      },
      {
        value: 'other',
        label: {
          'en-US': 'Other',
          'id-ID': 'Lainnya',
        },
      },
    ],
    required: true,
    step: 1,
    type: 'select',
    value: null,
    visibility: true,
  },
  {
    label: {
      "en-US": "Project Proponent Name",
      "id-ID": "Nama Pengembang Proyek"
    },
    name: 'projectProponentName',
    step: 1,
    type: 'text',
    value: '',
    visibility: [
      { name: 'role', value: 'project_proponent' }
    ],
  },
  {
    label: {
      "en-US": "Role Description",
      "id-ID": "Deskripsi Peran"
    },
    name: 'roleDescription',
    row: 5,
    step: 1,
    type: 'textarea',
    value: '',
    visibility: [
      { name: 'role', value: 'other' }
    ],
  },
  {
    label: {
      "en-US": "Project Name",
      "id-ID": "Nama Proyek"
    },
    name: 'projectName',
    required: true,
    step: 1,
    summary: true,
    type: 'text',
    value: '',
    visibility: true,
  },
  {
    label: {
      "en-US": "Project Description",
      "id-ID": "Deskripsi Proyek"
    },
    name: 'projectDescription',
    step: 1,
    summary: true,
    type: 'text',
    value: '',
    visibility: true,
  },
  {
    label: {
      "en-US": "Project Website",
      "id-ID": "Laman Proyek"
    },
    name: 'projectWebsite',
    step: 1,
    summary: true,
    type: 'text',
    value: '',
    visibility: true,
  },
  {
    label: {
      "en-US": "Estimated Project Start Date",
      "id-ID": "Perkiraan Tanggal Mulai Proyek"
    },
    name: 'estimatedProjectStartDate',
    step: 1,
    summary: true,
    type: 'date',
    value: '',
    visibility: true,
  },
  {
    label: {
      "en-US": "Latitude of Center Point",
      "id-ID": "Garis Lintang Titik Tengah"
    },
    max: 90,
    min: -90,
    name: 'latitudeOfCenterPoint',
    required: true,
    step: 1,
    type: 'coordinate',
    value: '',
    visibility: true,
  },
  {
    label: {
      "en-US": "Longitude of Center Point",
      "id-ID": "Garis Bujur Titik Tengah"
    },
    max: 180,
    min: -180,
    name: 'longitudeOfCenterPoint',
    required: true,
    step: 1,
    type: 'coordinate',
    value: '',
    visibility: true,
  },
  {
    label: {
      "en-US": "Financing Requirement",
      "id-ID": "Kebutuhan Pembiayaan"
    },
    name: 'capitalRequirement',
    options: [
      {
        value: 'yes',
        label: {
          "en-US": "Yes",
          "id-ID": "Ya"
        },
      },
      {
        value: 'no',
        label: {
          "en-US": "No",
          "id-ID": "Tidak"
        },
      },
    ],
    required: false,
    step: 1,
    summary: true,
    type: 'select',
    value: null,
    visibility: true,
  },
  {
    label: {
      "en-US": "Province",
      "id-ID": "Provinsi"
    },
    name: 'province',
    required: true,
    step: 1,
    type: 'text',
    value: '',
    visibility: true,
  },
  {
    label: {
      "en-US": "Area Distribution",
      "id-ID": "Distribusi Wilayah"
    },
    name: 'areaDistribution',
    options: [
      {
        value: 'scattered_areas',
        label: {
          "en-US": "Scattered Areas",
          "id-ID": "Daerah Tersebar"
        },
        score: 1,
      },
      {
        value: 'one_whole_area',
        label: {
          "en-US": "One Whole Area",
          "id-ID": "Satu Area Utuh"
        },
        score: 4,
      },
    ],
    required: true,
    step: 2,
    type: 'select',
    value: null,
    visibility: true
  },
  {
    label: {
      "en-US": "Area Permit Status",
      "id-ID": "Status Izin Kawasan"
    },
    name: 'areaPermitStatus',
    options: [
      {
        value: 'shm',
        label: {
          "en-US": "Freehold Title",
          "id-ID": "Sertifikat Hak Milik (SHM)"
        },
        score: 5,
      },
      {
        value: 'apl',
        label: {
          "en-US": "Other Use Areas",
          "id-ID": "Area Penggunaan Lainnya (APL)"
        },
        score: 2,
      },
      {
        value: 'iphps',
        label: {
          "en-US": "Social Forestry Forest Utilization Permit",
          "id-ID": "Izin Pemanfaatan Hutan Perhutanan Sosial (IPHPS)"
        },
        score: 5,
      },
      {
        value: 'pbph',
        label: {
          "en-US": "Forest Utilization Business Licensing",
          "id-ID": "Perizinan Berusaha Pemanfaatan Hutan (PBPH)"
        },
        score: 5,
      },
      {
        value: 'other',
        label: {
          "en-US": "Other",
          "id-ID": "Lainnya"
        },
        score: 1,
      },
    ],
    required: true,
    step: 2,
    type: 'select',
    value: null,
    visibility: true,
  },
  {
    label: {
      "en-US": "Area Permit Status Description",
      "id-ID": "Deskripsi Status Izin Kawasan"
    },
    name: 'areaPermitStatusDescription',
    row: 5,
    step: 2,
    type: 'textarea',
    value: '',
    visibility: [
      { name: 'areaPermitStatus', value: 'other' }
    ],
  },
  {
    label: {
      "en-US": "Area Size",
      "id-ID": "Ukuran Daerah"
    },
    min: 0,
    name: 'areaSize',
    required: true,
    step: 2,
    suffix: 'Ha',
    type: 'number',
    value: '',
    visibility: true,
  },
  {
    label: {
      "en-US": "Dominant Access",
      "id-ID": "Akses Dominan"
    },
    name: 'dominantAccess',
    options: [
      {
        value: 'road',
        label: {
          "en-US": "Road",
          "id-ID": "Jalan"
        },
        score: 1,
      },
      {
        value: 'river',
        label: {
          "en-US": "River",
          "id-ID": "Sungai"
        },
        score: 5,
      },
      {
        value: 'forest_path',
        label: {
          "en-US": "Forest Path",
          "id-ID": "Jalur Hutan"
        },
        score: 3,
      },
      {
        value: 'other',
        label: {
          "en-US": "Other",
          "id-ID": "Lainnya"
        },
        score: 1,
      },
    ],
    required: true,
    step: 2,
    type: 'select',
    multiple: true,
    value: null,
    visibility: true,
  },
  {
    label: {
      "en-US": "Dominant Access Description",
      "id-ID": "Deskripsi Akses Dominan"
    },
    name: 'dominantAccessDescription',
    row: 5,
    step: 2,
    type: 'textarea',
    value: '',
    visibility: [
      { name: 'dominantAccess', value: 'other' }
    ],
  },
  {
    label: {
      "en-US": "Ecosystem Type",
      "id-ID": "Tipe Ekosistem"
    },
    name: 'ecosystemType',
    options: [
      {
        value: 'mixed_crops',
        label: {
          "en-US": "Mixed Crops",
          "id-ID": "Tanaman Campuran"
        },
        // score: 3,
      },
      {
        value: 'mangrove',
        label: {
          "en-US": "Mangrove",
          "id-ID": "Bakau"
        },
        // score: 5,
      },
      {
        value: 'primary_forest',
        label: {
          "en-US": "Primary Forest",
          "id-ID": "Hutan Primer"
        },
        // score: 5,
      },
      {
        value: 'secondary_forest',
        label: {
          "en-US": "Secondary Forest",
          "id-ID": "Hutan Sekunder"
        },
        // score: 5,
      },
      {
        value: 'open_land',
        label: {
          "en-US": "Open Land",
          "id-ID": "Lahan Terbuka"
        },
        // score: 5,
      },
      {
        value: 'other',
        label: {
          "en-US": "Other",
          "id-ID": "Lainnya"
        },
        // score: 1,
      },
    ],
    required: true,
    step: 2,
    type: 'select',
    multiple: true,
    value: null,
    visibility: true,
  },
  {
    label: {
      "en-US": "Ecosystem Type Description",
      "id-ID": "Deskripsi Tipe Ekosistem"
    },
    name: 'ecosystemTypeDescription',
    row: 5,
    step: 2,
    type: 'textarea',
    value: '',
    visibility: [
      { name: 'ecosystemType', value: 'other' }
    ],
  },
  {
    label: {
      "en-US": "Land Status",
      "id-ID": "Status Tanah"
    },
    name: 'landStatus',
    options: [
      {
        value: 'production_forest',
        label: {
          "en-US": "Production Forest (HP)",
          "id-ID": "Hutan Produksi (HP)"
        },
        score: 5,
      },
      {
        value: 'production_forest_for_conversion',
        label: {
          "en-US": "Production Forest for Conversion Purpose (HPK)",
          "id-ID": "Hutan Produksi untuk Tujuan Konversi (HPK)"
        },
        score: 5,
      },
      {
        value: 'protection_forest',
        label: {
          "en-US": "Protection Forest (HL)",
          "id-ID": "Hutan Lindung (HL)"
        },
        score: 3,
      },
      {
        value: 'village_forest',
        label: {
          "en-US": "Village Forest (HD)",
          "id-ID": "Hutan Desa (HD)"
        },
        score: 4,
      },
      {
        value: 'other',
        label: {
          "en-US": "Other",
          "id-ID": "Lainnya"
        },
        score: 1,
      },
    ],
    required: true,
    step: 2,
    type: 'select',
    value: null,
    visibility: true,
  },
  {
    label: {
      "en-US": "Land Status Description",
      "id-ID": "Deskripsi Status Tanah"
    },
    name: 'landStatusDescription',
    row: 5,
    step: 2,
    type: 'textarea',
    value: '',
    visibility: [
      { name: 'landStatus', value: 'other' }
    ],
  },
  {
    accept: '.zip',
    label: {
      "en-US": "Project Area Boundaries Attachment",
      "id-ID": "Lampiran Batas Wilayah Proyek"
    },
    name: 'projectAreaBoundariesAttachment',
    required: true,
    step: 2,
    type: 'file',
    value: null,
    visibility: true,
    information: {
      "en-US": "Shapefiles of the project area boundaries",
      "id-ID": "Berkas Shapefile batas area proyek",
    },
  },
  {
    label: {
      "en-US": "Soil Type",
      "id-ID": "Jenis Tanah"
    },
    name: 'soilType',
    options: [
      {
        value: 'mineral',
        label: {
          "en-US": "Mineral",
          "id-ID": "Mineral"
        },
        // score: 4,
      },
      {
        value: 'peat',
        label: {
          "en-US": "Peat",
          "id-ID": "Gambut"
        },
        // score: 5,
      },
      {
        value: 'coastal',
        label: {
          "en-US": "Coastal",
          "id-ID": "Pesisir"
        },
        // score: 5,
      },
      {
        value: 'other',
        label: {
          "en-US": "Other",
          "id-ID": "Lainnya"
        },
        // score: 1,
      },
    ],
    required: true,
    step: 2,
    type: 'select',
    multiple: true,
    value: null,
    visibility: true,
  },
  {
    label: {
      "en-US": "Soil Type Description",
      "id-ID": "Deskripsi Jenis Tanah"
    },
    name: 'soilTypeDescription',
    row: 5,
    step: 2,
    type: 'textarea',
    value: '',
    visibility: [
      { name: 'soilType', value: 'other' }
    ],
  },
  {
    label: {
      "en-US": "Village",
      "id-ID": "Desa"
    },
    name: 'village',
    required: true,
    step: 2,
    type: 'text',
    value: '',
    visibility: true,
    information: {
      "en-US": "Comma separated village names.\nExample: Janju, Benua Baru Ilir, Hambau",
      "id-ID": "Nama desa dipisahkan oleh koma.\nContoh: Janju, Benua Baru Ilir, Hambau",
    },
  },

  {
    label: {
      "en-US": "Statistical Data of the Communities",
      "id-ID": "Data Statistik Masyarakat"
    },
    name: 'statisticalDataOfTheCommunities',
    options: [
      {
        value: 'yes',
        label: {
          "en-US": "Yes",
          "id-ID": "Ya"
        },
        score: 5,
      },
      {
        value: 'no',
        label: {
          "en-US": "No",
          "id-ID": "Tidak"
        },
        score: 3,
      },
    ],
    required: true,
    step: 3,
    type: 'select',
    value: null,
    visibility: true,
  },
  {
    label: {
      "en-US": "Communities Main Livelihood",
      "id-ID": "Mata pencaharian Utama Komunitas"
    },
    name: 'communitiesMainLivelihood',
    options: [
      {
        value: 'forest_product_collection',
        label: {
          'en-US': 'Forest Product Collection, Describe Below',
          'id-ID': 'Hasil Hutan Kayu, Jelaskan Dibawah',
        },
        score: 1,
      },
      {
        value: 'non_forest_product_collection',
        label: {
          'en-US': 'Non-forest Product Collection, Describe Below',
          'id-ID': 'Hasil Hutan Bukan Kayu, Jelaskan Dibawah',
        },
        score: 3,
      },
      {
        value: 'none',
        label: {
          'en-US': 'No Dependencies',
          'id-ID': 'Tidak Ada Ketergantungan',
        },
        score: 5,
      },
    ],
    required: true,
    step: 3,
    type: 'select',
    value: null,
    visibility: true,
  },
  {
    label: {
      "en-US": "Communities Dependencies Description",
      "id-ID": "Deskripsi Ketergantungan Dari Komunitas"
    },
    name: 'communitiesDependenciesDescription',
    row: 5,
    step: 3,
    type: 'textarea',
    value: '',
    visibility: [
      { name: 'communitiesDependencies', value: 'forest_product_collection' },
      { name: 'communitiesDependencies', value: 'non_forest_product_collection' },
    ],
  },
  {
    accept: '.pdf, .jpeg, .jpg, .doc, .docx, .xls, .xlsx',
    label: {
      "en-US": "Dominant Tree Species Photo",
      "id-ID": "Foto Jenis Pohon Dominan"
    },
    name: 'dominantTreeSpeciesPhoto',
    required: false,
    step: 2,
    type: 'file',
    value: null,
    visibility: true,
  },
  {
    label: {
      "en-US": "Dominant Tree Species Description",
      "id-ID": "Deskripsi Jenis Pohon Dominan"
    },
    name: 'dominantTreeSpecies',
    required: false,
    row: 5,
    step: 2,
    type: 'textarea',
    value: '',
    visibility: true,
  },
  {
    accept: '.pdf, .jpeg, .jpg, .doc, .docx, .xls, .xlsx',
    label: {
      "en-US": "Flora and Fauna Photo",
      "id-ID": "Foto Flora dan Fauna"
    },
    name: 'floraAndFaunaPhoto',
    required: false,
    step: 2,
    type: 'file',
    value: null,
    visibility: true,
  },
  {
    label: {
      "en-US": "Flora and Fauna Description",
      "id-ID": "Deskripsi Flora dan Fauna"
    },
    name: 'floraAndFaunaDescription',
    required: false,
    row: 5,
    step: 2,
    type: 'textarea',
    value: '',
    visibility: true,
  },
  {
    label: {
      "en-US": "Indigenous Communities",
      "id-ID": "Komunitas Adat"
    },
    name: 'indigenousCommunities',
    options: [
      {
        value: 'yes',
        label: {
          "en-US": "Yes",
          "id-ID": "Ya, Jelaskan Di Bawah"
        },
        score: 3,
      },
      {
        value: 'no',
        label: {
          "en-US": "No",
          "id-ID": "Tidak"
        },
        score: 3,
      },
    ],
    required: true,
    step: 3,
    type: 'select',
    value: null,
    visibility: true,
  },
  {
    label: {
      "en-US": "Indigenous Communities Description",
      "id-ID": "Deskripsi Komunitas Adat"
    },
    name: 'indigenousCommunitiesDescription',
    row: 5,
    step: 2,
    type: 'textarea',
    value: '',
    visibility: [
      { name: 'indigenousCommunities', value: 'yes' },
    ],
  },
  {
    accept: '.pdf, .jpeg, .jpg',
    label: {
      "en-US": "Legality Letter Type",
      "id-ID": "Jenis Surat Legalitas"
    },
    name: 'legalityLetterType',
    options: [
      {
        value: 'sk',
        label: {
          "en-US": "SK & Boundary Rules For Jasling",
          "id-ID": "SK & Aturan Batas Jasling"
        },
        score: 5,
      },
      {
        value: 'acquisition',
        label: {
          "en-US": "Acquisition",
          "id-ID": "Akuisisi"
        },
        score: 3,
      },
      {
        value: 'apl',
        label: {
          "en-US": "APL",
          "id-ID": "APL"
        },
        score: 1,
      },
    ],
    required: true,
    step: 2,
    type: 'select',
    value: null,
    visibility: true,
  },
  {
    accept: '.pdf, .jpeg, .jpg',
    label: {
      "en-US": "Legality Letter Attachment",
      "id-ID": "Lampiran Surat Legalitas"
    },
    name: 'legalityLetterAttachment',
    required: true,
    step: 2,
    type: 'file',
    value: null,
    visibility: true,
  },
  {
    label: {
      "en-US": "Monitoring Frequency",
      "id-ID": "Frekuensi Pemantauan"
    },
    min: 0,
    name: 'monitoringFrequency',
    required: false,
    step: 2,
    suffix: '/ Year',
    type: 'number',
    value: '',
    visibility: true,
  },
  {
    label: {
      "en-US": "Number of Communities Around The Permit Area",
      "id-ID": "Jumlah Komunitas di Sekitar Wilayah Izin"
    },
    min: 0,
    name: 'numberOfCommunitiesAroundThePermitArea',
    required: true,
    step: 3,
    type: 'number',
    value: '',
    visibility: true,
  },
  {
    label: {
      "en-US": "Number of Communities Inside The Permit Area",
      "id-ID": "Jumlah Komunitas di Dalam Wilayah Izin"
    },
    min: 0,
    name: 'numberOfCommunitiesInsideThePermitArea',
    required: true,
    step: 3,
    type: 'number',
    value: '',
    visibility: true,
  },
  {
    label: {
      "en-US": "Dispute with Local Communities",
      "id-ID": "Konflik dengan Komunitas Lokal"
    },
    name: 'disputeWithLocalCommunities',
    options: [
      {
        value: 'yes_unsolved',
        label: {
          "en-US": "Yes (Unsolved)",
          "id-ID": "Ya (Belum Terselesaikan)"
        },
        score: 1,
      },
      {
        value: 'yes_solved',
        label: {
          "en-US": "Yes (Solved)",
          "id-ID": "Ya (Terselesaikan)"
        },
        score: 3,
      },
      {
        value: 'no',
        label: {
          "en-US": "No",
          "id-ID": "Tidak"
        },
        score: 5,
      },
    ],
    required: false,
    step: 3,
    type: 'select',
    value: null,
    visibility: true,
  },
  {
    label: {
      "en-US": "Dispute with Local Communities Description",
      "id-ID": "Deskripsi Konflik dengan Komunitas Lokal"
    },
    name: 'disputeWithLocalCommunitiesDescription',
    row: 5,
    step: 3,
    type: 'textarea',
    value: '',
    visibility: [
      { name: 'previousOrCurrentDisputeWithLocalCommunities', value: 'yes_unsolved' },
      { name: 'previousOrCurrentDisputeWithLocalCommunities', value: 'yes_solved' }
    ],
  },
  {
    label: {
      "en-US": "Engagement with Local Communities",
      "id-ID": "Kemitraan dengan Komunitas Lokal"
    },
    name: 'engagementWithLocalCommunities',
    options: [
      {
        value: 'yes',
        label: {
          "en-US": "Yes",
          "id-ID": "Ya"
        },
      },
      {
        value: 'no',
        label: {
          "en-US": "No",
          "id-ID": "Tidak"
        },
      },
    ],
    required: false,
    step: 3,
    type: 'select',
    value: null,
    visibility: true,
  },
  {
    label: {
      "en-US": "Engagement with Local Communities Description",
      "id-ID": "Deskripsi Kemitraan dengan Komunitas Lokal"
    },
    name: 'engagementWithLocalCommunitiesDescription',
    row: 5,
    step: 3,
    type: 'textarea',
    value: '',
    visibility: [
      { name: 'previousOrCurrentEngagementWithLocalCommunities', value: 'other' }
    ],
  },
  {
    label: {
      "en-US": "Carbon Project Co-benefits",
      "id-ID": "Manfaat Tambahan Proyek Karbon"
    },
    name: 'carbonProjectCobenefits',
    options: [
      {
        value: 'yes',
        label: {
          "en-US": "Yes",
          "id-ID": "Ya"
        },
      },
      {
        value: 'no',
        label: {
          "en-US": "No",
          "id-ID": "Tidak"
        },
      },
    ],
    required: true,
    step: 3,
    type: 'select',
    value: null,
    visibility: true,
  },
  {
    multiple: true,
    label: {
      "en-US": "Carbon Project's Potential SDGs",
      "id-ID": "Potensi SDGs dari Proyek Karbon"
    },
    name: 'carbonProjectsPotentialSdgs',
    options: [
      {
        value: 1,
        label: {
          "en-US": "1. No Poverty",
          "id-ID": "1. Tidak Ada Kemiskinan"
        },
      },
      {
        value: 2,
        label: {
          "en-US": "2. Zero Hunger",
          "id-ID": "2. Nol Kelaparan"
        }
      },
      {
        value: 3,
        label: {
          "en-US": "3. Good Health and Well-Being",
          "id-ID": "3. Kesehatan dan Kesejahteraan yang Baik"
        },
      },
      {
        value: 4,
        label: {
          "en-US": "4. Quality Education",
          "id-ID": "4. Kualitas pendidikan"
        },
      },
      {
        value: 5,
        label: {
          "en-US": "5. Gender Equality",
          "id-ID": "5. Kesetaraan Gender"
        },
      },
      {
        value: 6,
        label: {
          "en-US": "6. Clean Water and Sanitation",
          "id-ID": "6. Air Bersih dan Sanitasi"
        },
      },
      {
        value: 7,
        label: {
          "en-US": "7. Affordable and Clean Energy",
          "id-ID": "7. Energi Terjangkau dan Bersih"
        },
      },
      {
        value: 8,
        label: {
          "en-US": "8. Decent Work and Economic Growth",
          "id-ID": "8. Pekerjaan Layak dan Pertumbuhan Ekonomi"
        },
      },
      {
        value: 9,
        label: {
          "en-US": "9. Industry, Innovation and Infrastructure",
          "id-ID": "9. Industri, Inovasi dan Infrastruktur"
        },
      },
      {
        value: 10,
        label: {
          "en-US": "10. Reduced Inqualities",
          "id-ID": "10. Mengurangi Ketimpangan"
        },
      },
      {
        value: 11,
        label: {
          "en-US": "11. Sustainable Cities and Communities",
          "id-ID": "11. Kota dan Komunitas yang Berkelanjutan"
        },
      },
      {
        value: 12,
        label: {
          "en-US": "12. Responsible Consumption and Production",
          "id-ID": "12. Konsumsi dan Produksi yang Bertanggung Jawab"
        },
      },
      {
        value: 13,
        label: {
          "en-US": "13. Climate Action",
          "id-ID": "13. Aksi Iklim"
        },
      },
      {
        value: 14,
        label: {
          "en-US": "14. Life Below Water",
          "id-ID": "14. Kehidupan Di Bawah Air"
        },
      },
      {
        value: 15,
        label: {
          "en-US": "15. Life On Land",
          "id-ID": "15. Kehidupan Di Darat"
        },
      },
      {
        value: 16,
        label: {
          "en-US": "16. Peace, Justice and Strong Institutions",
          "id-ID": "16. Perdamaian, Keadilan dan Institusi yang Kuat"
        },
      },
      {
        value: 17,
        label: {
          "en-US": "17. Partnership For The Goals",
          "id-ID": "17. Kemitraan Untuk Tujuan"
        },
      },
    ],
    required: false,
    step: 3,
    type: 'select',
    value: null,
    visibility: true,
  },
  {
    label: {
      "en-US": "Existing Carbon Project's SDGs Achievement",
      "id-ID": "Pencapaian SDGs dari Proyek Karbon yang Sudah Ada"
    },
    multiple: true,
    name: 'existingCarbonProjectSdgs',
    options: [
      {
        value: 0,
        label: {
          "en-US": "Not Available",
          "id-ID": "Tidak Ada"
        },
      },
      {
        value: 1,
        label: {
          "en-US": "1. No Poverty",
          "id-ID": "1. Tidak Ada Kemiskinan"
        },
      },
      {
        value: 2,
        label: {
          "en-US": "2. Zero Hunger",
          "id-ID": "2. Nol Kelaparan"
        }
      },
      {
        value: 3,
        label: {
          "en-US": "3. Good Health and Well-Being",
          "id-ID": "3. Kesehatan dan Kesejahteraan yang Baik"
        },
      },
      {
        value: 4,
        label: {
          "en-US": "4. Quality Education",
          "id-ID": "4. Kualitas pendidikan"
        },
      },
      {
        value: 5,
        label: {
          "en-US": "5. Gender Equality",
          "id-ID": "5. Kesetaraan Gender"
        },
      },
      {
        value: 6,
        label: {
          "en-US": "6. Clean Water and Sanitation",
          "id-ID": "6. Air Bersih dan Sanitasi"
        },
      },
      {
        value: 7,
        label: {
          "en-US": "7. Affordable and Clean Energy",
          "id-ID": "7. Energi Terjangkau dan Bersih"
        },
      },
      {
        value: 8,
        label: {
          "en-US": "8. Decent Work and Economic Growth",
          "id-ID": "8. Pekerjaan Layak dan Pertumbuhan Ekonomi"
        },
      },
      {
        value: 9,
        label: {
          "en-US": "9. Industry, Innovation and Infrastructure",
          "id-ID": "9. Industri, Inovasi dan Infrastruktur"
        },
      },
      {
        value: 10,
        label: {
          "en-US": "10. Reduced Inqualities",
          "id-ID": "10. Mengurangi Ketimpangan"
        },
      },
      {
        value: 11,
        label: {
          "en-US": "11. Sustainable Cities and Communities",
          "id-ID": "11. Kota dan Komunitas yang Berkelanjutan"
        },
      },
      {
        value: 12,
        label: {
          "en-US": "12. Responsible Consumption and Production",
          "id-ID": "12. Konsumsi dan Produksi yang Bertanggung Jawab"
        },
      },
      {
        value: 13,
        label: {
          "en-US": "13. Climate Action",
          "id-ID": "13. Aksi Iklim"
        },
      },
      {
        value: 14,
        label: {
          "en-US": "14. Life Below Water",
          "id-ID": "14. Kehidupan Di Bawah Air"
        },
      },
      {
        value: 15,
        label: {
          "en-US": "15. Life On Land",
          "id-ID": "15. Kehidupan Di Darat"
        },
      },
      {
        value: 16,
        label: {
          "en-US": "16. Peace, Justice and Strong Institutions",
          "id-ID": "16. Perdamaian, Keadilan dan Institusi yang Kuat"
        },
      },
      {
        value: 17,
        label: {
          "en-US": "17. Partnership For The Goals",
          "id-ID": "17. Kemitraan Untuk Tujuan"
        },
      },
    ],
    required: false,
    step: 3,
    type: 'select',
    value: null,
    visibility: true,
  },
  {
    label: {
      "en-US": "Upcoming Plan",
      "id-ID": "Rencana Mendatang"
    },
    name: 'upcomingPlan',
    required: false,
    row: 5,
    step: 3,
    type: 'textarea',
    value: null,
    visibility: true,
  },
]

export {
  defaultDetailEligibility,
};