export function FileIcon({ className = '', alternate = false }) {
  return <svg className={className} width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.25 3.125C9.25 3.45653 9.1183 3.77447 8.88388 4.00889C8.64946 4.24331 8.33152 4.375 8 4.375C7.66848 4.375 7.35054 4.24331 7.11612 4.00889C6.8817 3.77447 6.75 3.45653 6.75 3.125C6.75 2.79348 6.8817 2.47554 7.11612 2.24112C7.35054 2.0067 7.66848 1.875 8 1.875C8.33152 1.875 8.64946 2.0067 8.88388 2.24112C9.1183 2.47554 9.25 2.79348 9.25 3.125ZM11.0625 2.5C10.9191 1.79357 10.5358 1.15846 9.97766 0.702269C9.41953 0.246079 8.72085 -0.00312805 8 -0.00312805C7.27915 -0.00312805 6.58047 0.246079 6.02234 0.702269C5.46421 1.15846 5.08095 1.79357 4.9375 2.5H1.75C1.41848 2.5 1.10054 2.6317 0.866116 2.86612C0.631696 3.10054 0.5 3.41848 0.5 3.75V18.75C0.5 19.0815 0.631696 19.3995 0.866116 19.6339C1.10054 19.8683 1.41848 20 1.75 20H14.25C14.5815 20 14.8995 19.8683 15.1339 19.6339C15.3683 19.3995 15.5 19.0815 15.5 18.75V3.75C15.5 3.41848 15.3683 3.10054 15.1339 2.86612C14.8995 2.6317 14.5815 2.5 14.25 2.5H11.0625ZM8 6.25H4.875V4.375H2.375V18.125H13.625V4.375H11.125V6.25H8ZM4.25 9.6875C4.25 9.43886 4.34877 9.20041 4.52459 9.02459C4.7004 8.84878 4.93886 8.75 5.1875 8.75H10.8125C11.0611 8.75 11.2996 8.84878 11.4754 9.02459C11.6512 9.20041 11.75 9.43886 11.75 9.6875C11.75 9.93614 11.6512 10.1746 11.4754 10.3504C11.2996 10.5262 11.0611 10.625 10.8125 10.625H5.1875C4.93886 10.625 4.7004 10.5262 4.52459 10.3504C4.34877 10.1746 4.25 9.93614 4.25 9.6875ZM5.1875 11.875C4.93886 11.875 4.7004 11.9738 4.52459 12.1496C4.34877 12.3254 4.25 12.5639 4.25 12.8125C4.25 13.0611 4.34877 13.2996 4.52459 13.4754C4.7004 13.6512 4.93886 13.75 5.1875 13.75H10.8125C11.0611 13.75 11.2996 13.6512 11.4754 13.4754C11.6512 13.2996 11.75 13.0611 11.75 12.8125C11.75 12.5639 11.6512 12.3254 11.4754 12.1496C11.2996 11.9738 11.0611 11.875 10.8125 11.875H5.1875Z" fill={alternate ? '#4C638C' : '#C7ECE7'} />
  </svg>
}

export function FileWarningIcon({ className = '', alternate = false }) {
  return <svg className={className} width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.25 3.12499C9.25 3.45651 9.1183 3.77445 8.88388 4.00887C8.64946 4.24329 8.33152 4.37499 8 4.37499C7.66848 4.37499 7.35054 4.24329 7.11612 4.00887C6.8817 3.77445 6.75 3.45651 6.75 3.12499C6.75 2.79347 6.8817 2.47553 7.11612 2.24111C7.35054 2.00669 7.66848 1.87499 8 1.87499C8.33152 1.87499 8.64946 2.00669 8.88388 2.24111C9.1183 2.47553 9.25 2.79347 9.25 3.12499ZM11.0625 2.49999C10.9191 1.79356 10.5358 1.15844 9.97766 0.702253C9.41953 0.246064 8.72085 -0.00314331 8 -0.00314331C7.27915 -0.00314331 6.58047 0.246064 6.02234 0.702253C5.46421 1.15844 5.08095 1.79356 4.9375 2.49999H1.75C1.41848 2.49999 1.10054 2.63169 0.866116 2.86611C0.631696 3.10053 0.5 3.41847 0.5 3.74999V18.75C0.5 19.0815 0.631696 19.3995 0.866116 19.6339C1.10054 19.8683 1.41848 20 1.75 20H14.25C14.5815 20 14.8995 19.8683 15.1339 19.6339C15.3683 19.3995 15.5 19.0815 15.5 18.75V3.74999C15.5 3.41847 15.3683 3.10053 15.1339 2.86611C14.8995 2.63169 14.5815 2.49999 14.25 2.49999H11.0625ZM8 6.24999H4.875V4.37499H2.375V18.125H13.625V4.37499H11.125V6.24999H8ZM9.25 15C9.25 15.3315 9.1183 15.6495 8.88388 15.8839C8.64946 16.1183 8.33152 16.25 8 16.25C7.66848 16.25 7.35054 16.1183 7.11612 15.8839C6.8817 15.6495 6.75 15.3315 6.75 15C6.75 14.6685 6.8817 14.3505 7.11612 14.1161C7.35054 13.8817 7.66848 13.75 8 13.75C8.33152 13.75 8.64946 13.8817 8.88388 14.1161C9.1183 14.3505 9.25 14.6685 9.25 15ZM8.9375 9.06249C8.9375 8.81385 8.83873 8.57539 8.66291 8.39958C8.4871 8.22376 8.24864 8.12499 8 8.12499C7.75136 8.12499 7.5129 8.22376 7.33709 8.39958C7.16127 8.57539 7.0625 8.81385 7.0625 9.06249V11.5625C7.0625 11.8111 7.16127 12.0496 7.33709 12.2254C7.5129 12.4012 7.75136 12.5 8 12.5C8.24864 12.5 8.4871 12.4012 8.66291 12.2254C8.83873 12.0496 8.9375 11.8111 8.9375 11.5625V9.06249Z" fill={alternate ? '#4C638C' : '#C7ECE7'} />
  </svg>
}

export function FileDoneIcon({ className = '', alternate = false }) {
  return <svg className={className} width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 4.375C8.33152 4.375 8.64946 4.2433 8.88388 4.00888C9.1183 3.77446 9.25 3.45652 9.25 3.125C9.25 2.79348 9.1183 2.47554 8.88388 2.24112C8.64946 2.0067 8.33152 1.875 8 1.875C7.66848 1.875 7.35054 2.0067 7.11612 2.24112C6.8817 2.47554 6.75 2.79348 6.75 3.125C6.75 3.45652 6.8817 3.77446 7.11612 4.00888C7.35054 4.2433 7.66848 4.375 8 4.375ZM8 6.51427e-08C8.72041 -0.000146969 9.41874 0.248615 9.97681 0.704184C10.5349 1.15975 10.9184 1.79415 11.0625 2.5H14.25C14.5815 2.5 14.8995 2.6317 15.1339 2.86612C15.3683 3.10054 15.5 3.41848 15.5 3.75V18.75C15.5 19.0815 15.3683 19.3995 15.1339 19.6339C14.8995 19.8683 14.5815 20 14.25 20H1.75C1.41848 20 1.10054 19.8683 0.866116 19.6339C0.631696 19.3995 0.5 19.0815 0.5 18.75V3.75C0.5 3.41848 0.631696 3.10054 0.866116 2.86612C1.10054 2.6317 1.41848 2.5 1.75 2.5H4.9375C5.08158 1.79415 5.46511 1.15975 6.02319 0.704184C6.58126 0.248615 7.27959 -0.000146969 8 6.51427e-08ZM6.75 6.25H11.125V4.375H13.625V18.125H2.375V4.375H4.875V6.25H6.75ZM11.1625 10.35C11.2546 10.2642 11.3285 10.1607 11.3797 10.0457C11.431 9.93067 11.4585 9.80653 11.4607 9.68065C11.463 9.55477 11.4398 9.42974 11.3927 9.313C11.3455 9.19627 11.2753 9.09023 11.1863 9.0012C11.0973 8.91218 10.9912 8.842 10.8745 8.79485C10.7578 8.74769 10.6327 8.72454 10.5068 8.72676C10.381 8.72898 10.2568 8.75653 10.1418 8.80777C10.0268 8.85901 9.92333 8.93289 9.8375 9.025L7.375 11.4875L6.7875 10.9C6.60978 10.7344 6.37472 10.6442 6.13185 10.6485C5.88897 10.6528 5.65724 10.7512 5.48547 10.923C5.31371 11.0947 5.21532 11.3265 5.21103 11.5693C5.20675 11.8122 5.2969 12.0473 5.4625 12.225L6.7125 13.475C6.88828 13.6506 7.12656 13.7492 7.375 13.7492C7.62344 13.7492 7.86172 13.6506 8.0375 13.475L11.1625 10.35Z" fill={alternate ? '#4C638C' : '#C7ECE7'} />
  </svg>
}

export function BlowerIcon({ className = '' }) {
  return <svg className={className} width="41" height="39" viewBox="0 0 41 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.6382 0.658325L21.6582 2.63833L24.5915 5.57166C24.9948 6.02999 25.1965 6.56166 25.1965 7.16666C25.1965 7.77166 24.9948 8.33999 24.5915 8.74333L18.0832 15.3617L19.9165 17.3417L26.5715 10.7233C27.5432 9.64166 28.0198 8.44999 28.0198 7.16666C28.0198 5.88333 27.5432 4.67333 26.5715 3.59166L23.6382 0.658325ZM16.3415 4.36166L14.3615 6.34166L15.4798 7.36833C15.8832 7.77166 16.0848 8.32166 16.0848 8.99999C16.0848 9.67833 15.8832 10.2283 15.4798 10.6317L14.3615 11.6583L16.3415 13.6383L17.3682 12.52C18.3398 11.4383 18.8348 10.265 18.8348 8.99999C18.8348 7.67999 18.3398 6.48833 17.3682 5.38833L16.3415 4.36166ZM35.4998 7.27666C34.2348 7.27666 33.0615 7.77166 31.9798 8.74333L21.6582 19.0833L23.6382 20.9167L33.8682 10.7233C34.3265 10.265 34.8765 10.0267 35.4998 10.0267C36.1232 10.0267 36.6732 10.265 37.1315 10.7233L38.2498 11.8417L40.1382 9.86166L39.1115 8.74333C38.0298 7.77166 36.8198 7.27666 35.4998 7.27666ZM9.83317 12.6667L0.666504 38.3333L26.3332 29.1667L9.83317 12.6667ZM31.8332 18.2767C30.5498 18.2767 29.3765 18.7717 28.2765 19.7433L25.3615 22.6583L27.3415 24.6383L30.2565 21.7233C30.7148 21.265 31.2282 21.0267 31.8332 21.0267C32.4382 21.0267 32.9882 21.265 33.4465 21.7233L36.4165 24.6383L38.3415 22.75L35.4082 19.7433C34.3265 18.7717 33.1165 18.2767 31.8332 18.2767Z" fill="#3EB6AE" />
  </svg>
}