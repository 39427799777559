import { DoubleRightOutlined } from "@ant-design/icons"
import { Button, Col, Modal, Row, Tooltip } from "antd"
import React, { useEffect, useState } from "react"
import { useHandler } from "./handler_provider"
import Layout from "./layout"
import colors from "./colors"

function OwnedInquiries() {
  const handler = useHandler()
  const [lexicon, setLexicon] = useState({})
  useEffect(() => {
    handler.subToLexicon(setLexicon)
    return () => handler.unsubFromLexicon(setLexicon)
  }, [handler])
  const [
    ownedProjectAppealSummaries,
    setOwnedProjectAppealSummaries,
  ] = useState([])
  useEffect(() => {
    handler.requestOwnedProjectAppealSummariesLookup()
    handler.subToOwnedProjectAppealSummaries(
      setOwnedProjectAppealSummaries,
    )
    return () => {
      handler.unsubFromOwnedProjectAppealSummaries(
        setOwnedProjectAppealSummaries,
      )
    }
  }, [handler])
  const [
    ownedProjectAppealSummary,
    setOwnedProjectAppealSummary,
  ] = useState(null)

  return <>
    <Layout>
      <div className="layout-wrapper landing min-h-screen">
        <section className="section pb-0 hero-section py-0 min-h-screen" id="hero">
          <div className="bg-overlay bg-overlay-pattern"></div>
          <div className="container">
            <div className="row justify-content-center">
              <div
                className="col-lg-8 col-sm-10"
                style={{ minHeight: "70vh" }}
              >
                {/* <div className="text-center mt-lg-5 pt-5">
                      <h1 className="display-6 fw-semibold mb-3 lh-base">
                        Empower your business with{" "}
                        <span style={{ color: variables.TRU_GREEN_PRIMARY }}>
                          TruCarbon
                        </span>{" "}
                        Offset Your Emissions and Support Sustainable Projects
                      </h1>
                      <p className="lead text-muted lh-base">
                        Our curated catalog of high quality carbon offset
                        projects is designed to help your organization make a
                        real impact in the fight against climate change. Let us
                        help you choose the right offset project to guide your
                        organization towards a more sustainable future.
                      </p>
                    </div> */}

                {/* <div className="mt-4 mt-sm-5 pt-sm-5 mb-sm-n5 demo-carousel">
                      <div className="demo-img-patten-top d-none d-sm-block">
                        <img
                          src="assets/images/landing/img-pattern.png"
                          className="d-block img-fluid"
                          alt="..."
                        />
                      </div>
                      <div className="demo-img-patten-bottom d-none d-sm-block">
                        <img
                          src="assets/images/landing/img-pattern.png"
                          className="d-block img-fluid"
                          alt="..."
                        />
                      </div>
                      <div
                        className="carousel slide carousel-fade"
                        data-bs-ride="carousel"
                      >
                        <div className="carousel-inner shadow-lg p-2 bg-white rounded">
                          <div
                            className="carousel-item active"
                            data-bs-interval="2000"
                          >
                            <img
                              src={BannerSvg}
                              className="d-block w-100"
                              alt="..."
                              style={{ objectFit: "conver", height: "400px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                <section className="section py-8">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="mb-2">
                        <h1 className="fw-semibold">
                          {lexicon.track_requests}
                        </h1>
                      </div>
                      {ownedProjectAppealSummaries.length ? (
                        ownedProjectAppealSummaries.map((summary) => (
                          <div key={summary?.id} className="card product">
                            <div className="card-body">
                              <div className="row gy-3">
                                <div className="col-sm-auto">
                                  {/* <div className="avatar-lg bg-light rounded p-1">
                                        <img
                                          style={{
                                            height: "100%",
                                            width: "auto",
                                            objectFit: "cover",
                                            borderRadius: "10px",
                                          }}
                                          src={el.image}
                                          alt=""
                                          className="img-fluid d-block"
                                        />
                                      </div> */}
                                </div>
                                <div className="col-sm-8">
                                  <span className="d-flex gap-2 align-items-center">
                                    <span
                                      style={{
                                        color: "#405189",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      #{summary.id}
                                    </span>
                                    <span
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {summary.tradeType}
                                    </span>
                                    <span
                                      className="text-muted mb-1"
                                      style={{
                                        backgroundColor:
                                          "rgba(64,81,137,.1)",
                                        borderRadius: "8px",
                                        padding: "5px 10px",
                                        gap: "5px",
                                        color: "#405189",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {summary.status}
                                    </span>
                                  </span>
                                  <h3 className="fs-18 text-truncate mt-2">
                                    <div
                                      className="text-dark"
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {summary?.creditType}
                                    </div>
                                  </h3>
                                  <p>{lexicon.quantity}: {summary.quantity}</p>
                                </div>
                                <div className="col d-flex align-items-center">
                                  <div className="view-detail">
                                    <div className="divider" />
                                    <Button
                                      type="text"
                                      className="d-flex align-items-center gap-2"
                                      style={{ color: "#0ab39c" }}
                                      onClick={() => {
                                        setOwnedProjectAppealSummary(summary)
                                      }}
                                    >
                                      <div>{lexicon.see_details}</div>
                                      <DoubleRightOutlined
                                        style={{ color: "#0ab39c" }}
                                      />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <span
                          style={{
                            backgroundColor: "#E2E5ED",
                            border: "1px solid #C6CADC",
                            borderRadius: "8px",
                            padding: "8px",
                            width: "100%",
                            display: "block",
                          }}
                        >
                          Your Request List is Empty!{" "}
                          <a
                            href="/inquiry"
                            style={{
                              color: "#303D67",
                              fontWeight: "bold",
                            }}
                          >
                            Click Here
                          </a>{" "}
                          to Request Offset.
                        </span>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
    <DetailModal
      isOpen={ownedProjectAppealSummary}
      onClose={() => setOwnedProjectAppealSummary(null)}
      data={ownedProjectAppealSummary ?? {}}
    />
  </>
}

function DetailModal({ isOpen, onClose, data }) {
  function render(creditType) {
    switch (creditType) {
      case "Carbon Offset (CER/VER)":
        return `Quantity (tC0<sub>2</sub>)`;
      case "Renewable Energy Certificate (REC)":
        return `Quantity (MWh)`;
      default:
        return "";
    }
  }
  return (
    <>
      <Modal
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5>Buy Carbon Offset</h5>
            <span
              style={{ fontSize: "12px", color: colors.TRU_BLUE_PRIMARY }}
            >
              #{data?.id}
            </span>
          </div>
        }
        closable={false}
        open={isOpen}
        onCancel={() => onClose()}
        footer={[
          <Button key="back" onClick={() => onClose()}>
            Close
          </Button>,
        ]}
      >
        <div>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <p style={{ margin: "3px" }}>Request Type</p>
              <span
                style={{
                  display: "block",
                  padding: "6px",
                  backgroundColor: "#E9EBEC",
                  border: "1px solid #CED4DA",
                  borderRadius: "4px",
                  textTransform: "capitalize",
                }}
              >
                {data.tradeType}
              </span>
            </Col>
            <Col span={12}>
              <p style={{ margin: "3px", textTransform: "capitalize" }}>
                Instrument type
              </p>
              <span
                style={{
                  display: "block",
                  padding: "6px",
                  backgroundColor: "#E9EBEC",
                  border: "1px solid #CED4DA",
                  borderRadius: "4px",
                  textTransform: "capitalize",
                }}
              >
                {data.creditType}
              </span>
            </Col>
            <Col span={12}>
              <p
                style={{ margin: "3px" }}
                dangerouslySetInnerHTML={{ __html: render(data.creditType) }}
              ></p>
              <span
                style={{
                  display: "block",
                  padding: "6px",
                  backgroundColor: "#E9EBEC",
                  border: "1px solid #CED4DA",
                  borderRadius: "4px",
                }}
              >
                {data.quantity}
              </span>
            </Col>
            <Col span={12}>
              <p style={{ margin: "3px" }}>Production Year</p>
              <span
                style={{
                  display: "block",
                  padding: "6px",
                  backgroundColor: "#E9EBEC",
                  border: "1px solid #CED4DA",
                  borderRadius: "4px",
                }}
              >
                {data.productionYear}
              </span>
            </Col>
            {data?.creditType === "Carbon Offset (CER/VER)" && (
              <>
                <Col span={24}>
                  <p style={{ margin: "3px" }}>Carbon Mechanism</p>
                  <div
                    className="gap-1"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      padding: "6px",
                      backgroundColor: "#E9EBEC",
                      border: "1px solid #CED4DA",
                      borderRadius: "4px",
                    }}
                  >
                    {data?.mechanisms?.map((el, i) => (
                      <span
                        style={{
                          margin: "5px",
                          backgroundColor: "#3EB6AE",
                          color: "white",
                          padding: "5px 10px ",
                          borderRadius: "5px",
                          textTransform: "capitalize",
                        }}
                        key={i}
                      >
                        {el}
                      </span>
                    ))}
                  </div>
                </Col>
                <Col span={24}>
                  <p style={{ margin: "3px" }}>Carbon Offset Category</p>
                  <div
                    className="gap-1"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      padding: "6px",
                      backgroundColor: "#E9EBEC",
                      border: "1px solid #CED4DA",
                      borderRadius: "4px",
                    }}
                  >
                    {data?.sectors?.map((el, i) => (
                      <span
                        style={{
                          margin: "5px 5px 5px 0",
                          margin: "0 10px 0 0",
                          backgroundColor: "#3EB6AE",
                          color: "white",
                          padding: "5px 10px ",
                          borderRadius: "5px",
                          textTransform: "capitalize",
                        }}
                        key={i}
                      >
                        {el}
                      </span>
                    ))}
                  </div>
                </Col>
                <Col span={24}>
                  <p style={{ margin: "3px" }}>SDGs Type</p>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      padding: "6px",
                      backgroundColor: "#E9EBEC",
                      border: "1px solid #CED4DA",
                      borderRadius: "4px",
                    }}
                  >
                    {data?.sdgs?.map((el) => (
                      <Tooltip
                        style={{ textTransform: "capitalize" }}
                        key={el.title}
                        title={el.title}
                      >
                        <img
                          src={el.logo}
                          style={{
                            height: "60px",
                            width: "60px",
                            objectFit: "cover",
                            margin: "0 5px 5px 0",
                          }}
                          alt=""
                        />
                      </Tooltip>
                      // <span
                      //   style={{
                      //     margin: "5px 5px 5px 0",
                      //     backgroundColor: "#3EB6AE",
                      //     color: "white",
                      //     padding: "5px 10px ",
                      //     borderRadius: "5px",
                      //   }}
                      //   key={el.title}
                      // >
                      //   {el.title}
                      // </span>
                    ))}
                  </div>
                </Col>
              </>
            )}

            {data.description && (
              <>
                <Col span={24}>
                  <p style={{ margin: "3px" }}>Additional Description</p>
                  <span
                    style={{
                      display: "block",
                      padding: "6px",
                      backgroundColor: "#E9EBEC",
                      border: "1px solid #CED4DA",
                      borderRadius: "4px",
                    }}
                  >
                    {data.description}
                  </span>
                </Col>
              </>
            )}
          </Row>
        </div>
      </Modal>
    </>
  );
}

export default OwnedInquiries