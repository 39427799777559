const Server = (options) => {
  if (!options.url) {
    throw Error('some tru carbon options are missing')
  }
  const url = options.url
  const utf8Encoder = new TextEncoder()

  async function requestUserRegistration(request) {
    try {
      const endpoint = `${url}/user-registration`
      const response = await fetch(endpoint, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(omit('token', request)),
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestUserTokenRenewal(request) {
    try {
      const endpoint = `${url}/user-token-renewal`
      const response = await fetch(endpoint, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(omit('token', request)),
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestUserOtpRenewal(request) {
    try {
      const endpoint = `${url}/user-otp-renewal`
      const response = await fetch(endpoint, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(omit('token', request)),
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestUserPasswordRenewal(request) {
    try {
      const endpoint = `${url}/user-password-renewal`
      const response = await fetch(endpoint, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(omit('token', request)),
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestUserInfoLookup(request) {
    try {
      const endpoint = `${url}/user-info-lookup`
      const inlined = JSON.stringify(request ?? {})
      const digest = await window.crypto.subtle
        .digest('SHA-1', utf8Encoder.encode(inlined))
        .then(buffer => Array.from(new Uint8Array(buffer)))
        .then(bytes => bytes.map(value => String.fromCharCode(value)))
        .then(characters => window.btoa(characters.join('')))
      const response = await fetch(`${endpoint}?digest=${digest}`, {
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Inlined-Body': inlined,
        },
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestCreditTypesLookup(request) {
    try {
      const endpoint = `${url}/credit-types-lookup`
      const inlined = JSON.stringify(request ?? {})
      const digest = await window.crypto.subtle
        .digest('SHA-1', utf8Encoder.encode(inlined))
        .then(buffer => Array.from(new Uint8Array(buffer)))
        .then(bytes => bytes.map(value => String.fromCharCode(value)))
        .then(characters => window.btoa(characters.join('')))
      const response = await fetch(`${endpoint}?digest=${digest}`, {
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Inlined-Body': inlined,
        },
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestSdgsLookup(request) {
    try {
      const endpoint = `${url}/sdgs-lookup`
      const inlined = JSON.stringify(request ?? {})
      const digest = await window.crypto.subtle
        .digest('SHA-1', utf8Encoder.encode(inlined))
        .then(buffer => Array.from(new Uint8Array(buffer)))
        .then(bytes => bytes.map(value => String.fromCharCode(value)))
        .then(characters => window.btoa(characters.join('')))
      const response = await fetch(`${endpoint}?digest=${digest}`, {
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Inlined-Body': inlined,
        },
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestMechanismsLookup(request) {
    try {
      const endpoint = `${url}/mechanisms-lookup`
      const inlined = JSON.stringify(request ?? {})
      const digest = await window.crypto.subtle
        .digest('SHA-1', utf8Encoder.encode(inlined))
        .then(buffer => Array.from(new Uint8Array(buffer)))
        .then(bytes => bytes.map(value => String.fromCharCode(value)))
        .then(characters => window.btoa(characters.join('')))
      const response = await fetch(`${endpoint}?digest=${digest}`, {
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Inlined-Body': inlined,
        },
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestSectorsLookup(request) {
    try {
      const endpoint = `${url}/sectors-lookup`
      const inlined = JSON.stringify(request ?? {})
      const digest = await window.crypto.subtle
        .digest('SHA-1', utf8Encoder.encode(inlined))
        .then(buffer => Array.from(new Uint8Array(buffer)))
        .then(bytes => bytes.map(value => String.fromCharCode(value)))
        .then(characters => window.btoa(characters.join('')))
      const response = await fetch(`${endpoint}?digest=${digest}`, {
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Inlined-Body': inlined,
        },
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestProjectAppealSubmission(request) {
    try {
      const endpoint = `${url}/project-appeal-submission`
      const response = await fetch(endpoint, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(omit('token', request)),
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestOwnedProjectAppealSummariesLookup(request) {
    try {
      const endpoint = `${url}/owned-project-appeal-summaries-lookup`
      const inlined = JSON.stringify(request ?? {})
      const digest = await window.crypto.subtle
        .digest('SHA-1', utf8Encoder.encode(inlined))
        .then(buffer => Array.from(new Uint8Array(buffer)))
        .then(bytes => bytes.map(value => String.fromCharCode(value)))
        .then(characters => window.btoa(characters.join('')))
      const response = await fetch(`${endpoint}?digest=${digest}`, {
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Inlined-Body': inlined,
        },
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestOwnedProjectAppealLookup(request) {
    try {
      const endpoint = `${url}/owned-project-appeal-lookup`
      const inlined = JSON.stringify(request ?? {})
      const digest = await window.crypto.subtle
        .digest('SHA-1', utf8Encoder.encode(inlined))
        .then(buffer => Array.from(new Uint8Array(buffer)))
        .then(bytes => bytes.map(value => String.fromCharCode(value)))
        .then(characters => window.btoa(characters.join('')))
      const response = await fetch(`${endpoint}?digest=${digest}`, {
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Inlined-Body': inlined,
        },
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestQuestionnaireSubmission(request) {
    try {
      const endpoint = `${url}/questionnaire-submission`
      const response = await fetch(endpoint, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(omit('token', request)),
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestProjectSubmission(request) {
    try {
      const endpoint = `${url}/project-submission`
      const response = await fetch(endpoint, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(omit('token', request)),
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestOwnedProjectSummariesLookup(request) {
    try {
      const endpoint = `${url}/owned-project-summaries-lookup`
      const inlined = JSON.stringify(request ?? {})
      const digest = await window.crypto.subtle
        .digest('SHA-1', utf8Encoder.encode(inlined))
        .then(buffer => Array.from(new Uint8Array(buffer)))
        .then(bytes => bytes.map(value => String.fromCharCode(value)))
        .then(characters => window.btoa(characters.join('')))
      const response = await fetch(`${endpoint}?digest=${digest}`, {
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Inlined-Body': inlined,
        },
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestOwnedProjectLookup(request) {
    try {
      const endpoint = `${url}/owned-project-lookup`
      const inlined = JSON.stringify(request ?? {})
      const digest = await window.crypto.subtle
        .digest('SHA-1', utf8Encoder.encode(inlined))
        .then(buffer => Array.from(new Uint8Array(buffer)))
        .then(bytes => bytes.map(value => String.fromCharCode(value)))
        .then(characters => window.btoa(characters.join('')))
      const response = await fetch(`${endpoint}?digest=${digest}`, {
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Inlined-Body': inlined,
        },
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestListedProjectSummariesLookup(request) {
    try {
      const endpoint = `${url}/listed-project-summaries-lookup`
      const inlined = JSON.stringify(request ?? {})
      const digest = await window.crypto.subtle
        .digest('SHA-1', utf8Encoder.encode(inlined))
        .then(buffer => Array.from(new Uint8Array(buffer)))
        .then(bytes => bytes.map(value => String.fromCharCode(value)))
        .then(characters => window.btoa(characters.join('')))
      const response = await fetch(`${endpoint}?digest=${digest}`, {
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Inlined-Body': inlined,
        },
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestListedProjectLookup(request) {
    try {
      const endpoint = `${url}/listed-project-lookup`
      const inlined = JSON.stringify(request ?? {})
      const digest = await window.crypto.subtle
        .digest('SHA-1', utf8Encoder.encode(inlined))
        .then(buffer => Array.from(new Uint8Array(buffer)))
        .then(bytes => bytes.map(value => String.fromCharCode(value)))
        .then(characters => window.btoa(characters.join('')))
      const response = await fetch(`${endpoint}?digest=${digest}`, {
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Inlined-Body': inlined,
        },
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestProjectBooking(request) {
    try {
      const endpoint = `${url}/project-booking`
      const response = await fetch(endpoint, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(omit('token', request)),
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestOwnedBookingSummariesLookup(request) {
    try {
      const endpoint = `${url}/owned-booking-summaries-lookup`
      const inlined = JSON.stringify(request ?? {})
      const digest = await window.crypto.subtle
        .digest('SHA-1', utf8Encoder.encode(inlined))
        .then(buffer => Array.from(new Uint8Array(buffer)))
        .then(bytes => bytes.map(value => String.fromCharCode(value)))
        .then(characters => window.btoa(characters.join('')))
      const response = await fetch(`${endpoint}?digest=${digest}`, {
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Inlined-Body': inlined,
        },
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestProjectFunding(request) {
    try {
      const endpoint = `${url}/project-funding`
      const response = await fetch(endpoint, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(omit('token', request)),
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestEligibilityCheckDrafting(request) {
    try {
      const endpoint = `${url}/eligibility-check-drafting`
      const response = await fetch(endpoint, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(omit('token', request)),
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestEligibilityCheckSubmission(request) {
    try {
      const endpoint = `${url}/eligibility-check-submission`
      const response = await fetch(endpoint, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(omit('token', request)),
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestOwnedEligibilityCheckSummariesLookup(request) {
    try {
      const endpoint = `${url}/owned-eligibility-check-summaries-lookup`
      const response = await fetch(endpoint, {
        credentials: 'include',
        method: 'GET',
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestOwnedEligibilityCheckDetailLookup(request) {
    try {
      const endpoint = `${url}/owned-eligibility-check-detail-lookup`
      const params = Object.entries(request)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
      const response = await fetch(`${endpoint}?${params}`, {
        credentials: 'include',
        method: 'GET',
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  return {
    requestUserRegistration,
    requestUserTokenRenewal,
    requestUserOtpRenewal,
    requestUserPasswordRenewal,
    requestUserInfoLookup,
    requestCreditTypesLookup,
    requestSdgsLookup,
    requestMechanismsLookup,
    requestSectorsLookup,
    requestProjectAppealSubmission,
    requestOwnedProjectAppealSummariesLookup,
    requestOwnedProjectAppealLookup,
    requestQuestionnaireSubmission,
    requestProjectSubmission,
    requestOwnedProjectSummariesLookup,
    requestOwnedProjectLookup,
    requestListedProjectSummariesLookup,
    requestListedProjectLookup,
    requestProjectBooking,
    requestOwnedBookingSummariesLookup,
    requestProjectFunding,
    requestEligibilityCheckDrafting,
    requestEligibilityCheckSubmission,
    requestOwnedEligibilityCheckSummariesLookup,
    requestOwnedEligibilityCheckDetailLookup,
  }
}

function omit(key, target) {
  const { [key]: omitted, ...result } = target
  return result;
}

export default Server