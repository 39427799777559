// Import Library
import React from 'react';
import { Input, Typography } from 'antd';

/**
* @param {{ 
    * classContainer: string;
    * classContainerAdditional: string;
    * className: string;
    * classNameAdditional: string;
    * label: string;
    * name: string;
    * onChange: React.ChangeEventHandler<HTMLInputElement>;
    * placeholder: string;
    * required: boolean;
    * row: number | null;
    * size: "large" | "middle" | "small";
    * type: React.HTMLInputTypeAttribute | undefined;
    * value: string | number | readonly string[] | undefined;
* }} props
**/

const { TextArea } = Input;

export default function InputText(props) {

    const {
        classContainer = 'text-[#4C638C]',
        classContainerAdditional = '',
        className = 'bg-transparent [&>input]:bg-transparent placeholder:text-slate-400 disabled:text-slate-400 text-[#4C638C] [&:not(:placeholder-shown)]:!border-[#4cdfd5]',
        classNameAdditional = '',
        disabled = false,
        label = '',
        name = '',
        onChange = null,
        placeholder = props?.label,
        required = false,
        row = null,
        size = 'large',
        type = 'text',
        value = undefined,
        ...rest
    } = props;

    const onValueChange = (e) => {
        if (type === 'number') {
            if (rest.max && +e.target.value > +rest.max) e.target.value = +rest.max;
            else if (rest.min && +e.target.value < +rest.min) e.target.value = +rest.min;
        }

        onChange(e);
    }

    return (
        row ?
            <TextArea
                {...rest}
                autoSize={{ minRows: row, maxRows: row }}
                disabled={disabled}
                className={[className, classNameAdditional].join(' ')}
                name={name}
                onChange={onValueChange}
                placeholder={placeholder}
                required={+required}
                row={row}
                size={size}
                type={type}
                value={value}
            />
            :
            <Input
                {...rest}
                className={[className, classNameAdditional].join(' ')}
                disabled={disabled}
                name={name}
                onChange={onValueChange}
                placeholder={placeholder}
                required={+required}
                size={size}
                type={type}
                value={value}
            />
    )
}
