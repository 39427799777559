import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useHandler } from "./handler_provider";
import Layout from "./layout";
import { DoubleRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

function OwnedBookings() {
  const handler = useHandler()
  const [lexicon, setLexicon] = useState({})
  useEffect(() => {
    handler.subToLexicon(setLexicon)
    return () => handler.unsubFromLexicon(setLexicon)
  }, [handler])
  const [
    ownedBookingSummaries,
    setOwnedBookingSummaries,
  ] = useState('waiting')
  useEffect(() => {
    handler.requestOwnedBookingSummariesLookup()
    handler.subToOwnedBookingSummaries(setOwnedBookingSummaries)
    return () => {
      handler.unsubFromOwnedBookingSummaries(setOwnedBookingSummaries)
    }
  }, [handler])

  if (ownedBookingSummaries === 'waiting' || !ownedBookingSummaries) {
    return <></>
  } else {
    return <Layout>
      <div className="layout-wrapper landing min-h-screen">
        <section className="section pb-0 hero-section py-0 min-h-screen" id="hero">
          <div className="bg-overlay bg-overlay-pattern"></div>
          <div className="container">
            <div className="row justify-content-center">
              <div
                className="col-lg-8 col-sm-10"
                style={{ minHeight: "70vh" }}
              >
                <section className="section py-8">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="mb-2">
                        <h1 className="fw-semibold">
                          {lexicon.track_bookings}
                        </h1>
                      </div>
                      {ownedBookingSummaries.length ? (
                        ownedBookingSummaries.map((summary) => (
                          <div key={summary?.id} className="card product">
                            <div className="card-body">
                              <div className="row gy-3">
                                <div className="col-sm-8">
                                  <span className="d-flex gap-2 align-items-center">
                                    <span
                                      style={{
                                        color: "#405189",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      #{summary.id}
                                    </span>
                                    {/* <span
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {summary.submitterCompanyName}
                                    </span> */}
                                    <span
                                      className="text-muted mb-1"
                                      style={{
                                        backgroundColor:
                                          "rgba(64,81,137,.1)",
                                        borderRadius: "8px",
                                        padding: "5px 10px",
                                        gap: "5px",
                                        color: "#405189",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {summary.projectYear}
                                    </span>
                                  </span>
                                  <h3 className="fs-18 text-truncate mt-2">
                                    <div
                                      className="text-dark"
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {summary.projectName}
                                    </div>
                                  </h3>
                                  <div className="font-semibold">
                                    {lexicon.amount}: &nbsp;
                                    <div className="inline">
                                      {summary.amount.toLocaleString()} tCO<sub>2</sub>e
                                    </div>
                                  </div>
                                </div>
                                <div className="col d-flex align-items-center">
                                  <div className="view-detail">
                                    <div className="divider" />
                                    <Link
                                      to={`/listed-projects/${summary.projectId}`}
                                      type="text"
                                      className="d-flex align-items-center gap-2"
                                      style={{ color: "#0ab39c" }}
                                    >
                                      <div>{lexicon.view_page}</div>
                                      <DoubleRightOutlined
                                        style={{ color: "#0ab39c" }}
                                      />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <span
                          style={{
                            backgroundColor: "#E2E5ED",
                            border: "1px solid #C6CADC",
                            borderRadius: "8px",
                            padding: "8px",
                            width: "100%",
                            display: "block",
                          }}
                        >
                          You Haven't Book Any Projects!{" "}
                          <Link
                            to="/"
                            style={{
                              color: "#303D67",
                              fontWeight: "bold",
                            }}
                          >
                            Click Here
                          </Link>{" "}
                          to Browse.
                        </span>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  }
}

export default OwnedBookings