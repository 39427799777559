import { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { useHandler } from "./handler_provider"
import Loading from "./loading"
import Notifier from "./notifier"

function Root() {
  const handler = useHandler()
  const navigate = useNavigate()
  useEffect(() => {
    handler.subToRedirect(navigate)
    return () => handler.unsubFromRedirect(navigate)
  }, [handler, navigate])

  return <>
    <Notifier />
    <Loading />
    <Outlet />
  </>
}

export default Root