import { mdiApproximatelyEqual, mdiClipboardTextClockOutline, mdiInformationOutline, mdiMapMarkerOutline, mdiPlusBoxOutline } from "@mdi/js";
import Icon from '@mdi/react';
import { InputNumber } from "antd";
import d2lIntl from 'd2l-intl';
import { useCallback, useEffect, useState } from "react";
import { Link, createSearchParams, useLocation, useParams } from "react-router-dom";
import ExchangeFundsLineIcon from "remixicon-react/ExchangeFundsLineIcon";
import { useHandler } from "./handler_provider";
import Layout from "./layout";
import NotFound from "./not_found";

function ListedProject() {
  const handler = useHandler()
  const [lexicon, setLexicon] = useState('waiting')
  useEffect(() => {
    handler.subToLexicon(setLexicon)
    return () => handler.unsubFromLexicon(setLexicon)
  }, [handler])
  const coalesceFromLexicon = (key) => {
    key = key.trim()
      .replaceAll(' ', '_')
      .replaceAll(/\W/g, '')
      .toLowerCase()
    return lexicon[key] ?? key
  }
  const [token, setToken] = useState('waiting')
  useEffect(() => {
    handler.subToToken(setToken)
    return () => handler.unsubFromToken(setToken)
  }, [handler])
  const [userInfo, setUserInfo] = useState('waiting')
  useEffect(() => {
    handler.subToUserInfo(setUserInfo)
    return () => handler.unsubFromUserInfo(setUserInfo)
  }, [handler])
  const [listedProject, setListedProject] = useState('waiting')
  const params = useParams()
  useEffect(() => {
    handler.requestListedProjectLookup({ id: parseInt(params.id) })
    handler.subToListedProject(setListedProject)
    return () => {
      handler.unsubFromListedProject(setListedProject)
    }
  }, [handler, params])
  const location = useLocation()
  const [isOwned, setIsOwned] = useState('waiting')
  const [isBooked, setIsBooked] = useState('waiting')
  const [bookedAmount, setBookedAmount] = useState('waiting')
  const [isFunded, setIsFunded] = useState('waiting')
  useEffect(() => {
    if (
      userInfo !== 'waiting' && userInfo &&
      listedProject !== 'waiting' && listedProject
    ) {
      setIsOwned(userInfo.id === listedProject.submitterId)
      setIsBooked(!!listedProject.bookings[userInfo.id])
      setBookedAmount(listedProject.bookings[userInfo.id])
      setIsFunded(!!listedProject.fundings[userInfo.id])
    }
  }, [userInfo, listedProject])
  useEffect(() => {
    if (listedProject !== 'waiting' && listedProject) {
      const navbar = document.getElementById('navbar')
      const subNavbar = document.getElementById('sub-navbar')
      const observer = new ResizeObserver(([navbar]) => {
        requestAnimationFrame(() => {
          subNavbar.style.top = `${navbar.target.offsetHeight - 0.9}px`
        })
      })
      observer.observe(navbar)
      return () => observer.unobserve(navbar)
    }
  }, [listedProject])
  const [amount, setAmount] = useState(1000)
  const setValidAmount = useCallback((value) => {
    if (
      value &&
      value >= 1000 &&
      value <= listedProject.potentialOffset
    ) {
      setAmount(value)
    }
  }, [listedProject])

  if (
    lexicon === 'waiting' ||
    listedProject === 'waiting'
  ) {
    return <></>
  } else if (!listedProject) {
    return <NotFound />
  } else {
    return <Layout>
      <div
        id="sub-navbar"
        className={[
          'flex',
          'flex-col',
          'gap-2',
          'px-14',
          'py-6',
          'sticky',
          'bg-white',
          'z-10',
        ].join(' ')}
      >
        <div className="font-semibold text-3xl text-black bg-white">
          {listedProject.name}
        </div>
        <div className="flex flex-row items-center">
          <Icon color="#4C638C" path={mdiMapMarkerOutline} size={1} />
          <div className="flex flex-row text-lg text-[#4C638C]">
            {listedProject.location}
          </div>
        </div>
      </div>
      <div className="min-h-screen flex flex-col font-sans bg-[#F3F3F9]">
        <div className={[
          'flex',
          'flex-col-reverse',
          'lg:flex-row',
          'gap-3',
          'px-4',
          'lg:px-14',
          'py-8',
        ].join(' ')}>
          <div className={[
            'bg-white',
            'flex-[0.7_1_0%]',
            'border',
            'shadow-md',
            'rounded-md',
            'p-3',
            'flex',
            'flex-col',
            'gap-3',
          ].join(' ')}>
            <div className="flex flex-col lg:flex-row gap-3">
              <div className={[
                'flex-1',
                'bg-slate-300',
                'aspect-[1/0.7]',
                'rounded-md',
                'flex',
                'flex-row',
              ].join(' ')}>
                {listedProject.image && <img
                  alt=""
                  src={`data:image/jpeg;base64,${listedProject.image}`}
                  className="flex-1 rounded-md object-cover"
                />}
              </div>
              <div className="flex-1 flex flex-col gap-3">
                <div className="flex flex-col">
                  <div className="text-lg font-medium">
                    {lexicon.profile}
                  </div>
                  <div className="flex gap-1">
                    <Icon path={mdiClipboardTextClockOutline} size={1} />
                    <div>{listedProject.year}</div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="text-lg font-medium">
                    {lexicon.project_data}
                  </div>
                  <div className="flex gap-1">
                    <div className="font-semibold">
                      {lexicon.land_size}:
                    </div>
                    <div className="flex items-center">
                      <Icon path={mdiApproximatelyEqual} size={1} />
                      {listedProject.area.toLocaleString()} Hectares
                    </div>
                  </div>
                  {
                    !listedProject.potentialOffset
                      ? <></>
                      : <div className="flex gap-1">
                        <div className="font-semibold">
                          {lexicon.credits}:
                        </div>
                        <div className="flex items-center">
                          <Icon path={mdiApproximatelyEqual} size={1} />
                          {listedProject.potentialOffset?.toLocaleString()} Tonnes
                        </div>
                      </div>
                  }
                  {Object.entries(listedProject.extra).map(([key, value]) => {
                    return <div key={key} className="flex gap-1">
                      <div className="font-semibold capitalize">
                        {coalesceFromLexicon(key)}:
                      </div>
                      <div className="capitalize">
                        {value}
                      </div>
                    </div>
                  })}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <div className="text-lg font-medium">
                {lexicon.description}
              </div>
              <div className="whitespace-pre-line">
                {listedProject.description}
              </div>
            </div>
            <div className="flex flex-col gap-1">
              {listedProject.sdgs && <>
                <div className="text-lg font-medium">
                  {lexicon.sustainable_development_goals}
                </div>
                <div className="flex flex-row gap-2">
                  {listedProject.sdgs.split(',').map((value, index) => {
                    value = parseInt(value)
                    return <div key={index} className={[
                      'bg-slate-300',
                      'flex',
                      'flex-row',
                      'justify-center',
                      'items-center',
                      'rounded-md',
                      'h-16',
                      'aspect-square',
                    ].join(' ')}>
                      <img alt="" src={`/assets/sdgs/${value}.png`} className="object-cover" />
                    </div>
                  })}
                </div>
              </>}
            </div>
          </div>
          <div className="flex-[0.3_1_0%] flex flex-col gap-3">
            <div className="flex flex-col bg-[#C7ECE7] px-[1rem] py-2 shadow-md rounded-lg">
              <div className="text-[#4C638C] font-semibold text-lg">
                {lexicon.carbon_credit}
              </div>
              <div className="text-[#4C638C] text-sm">
                {lexicon.agriculture_forestry_and_other_land_use}
              </div>
            </div>
            {(() => {
              if (token === 'waiting') {
                return <></>
              } else if (!token) {
                return <div className="flex flex-col gap-2 p-4 bg-white rounded-md shadow-md">
                  <div className="text-xl font-semibold">
                    Sign in to buy credits
                  </div>
                  <div>
                    You are seeing a public version of the project page.
                    For doing transaction, please sign in.
                  </div>
                  <div className="flex flex-col gap-1">
                    <Link
                      to={[
                        process.env.REACT_APP_ACCOUNT_URL,
                        '/login?',
                        `redirect_=${window.location.href}`
                      ].join('')}
                      className={[
                        'bg-[#3EB6AE]',
                        'rounded-md',
                        'text-white',
                        'text-center',
                        'py-2',
                      ].join(' ')}
                    >
                      Sign In
                    </Link>
                    <div className="text-sm flex flex-row gap-1 justify-center items-center">
                      Don't have an account?
                      <Link to="/register" className="text-[#3EB6AE]">
                        Sign Up
                      </Link>
                    </div>
                  </div>
                </div>
              } else if (isOwned) {
                return <div className={[
                  'fixed',
                  'left-0',
                  'bottom-[-0.5px]',
                  'w-full',
                  'lg:static',
                  'flex',
                  'flex-col',
                  'gap-2',
                  'p-[0.75rem]',
                  'lg:p-4',
                  'z-50',
                  'lg:z-0',
                  'rounded-lg',
                  'rounded-b-none',
                  'lg:rounded-lg',
                  'bg-[white]',
                  'shadow-md',
                  'text-base',
                ].join(' ')}>
                  <div className={[
                    'px-4',
                    'py-2',
                    'rounded-md',
                    'flex',
                    'flex-row',
                    'justify-between',
                    'items-center',
                    'bg-gray-400',
                  ].join(' ')}>
                    <div className="font-semibold text-white text-sm">
                      {lexicon.you_own_this_project}
                    </div>
                    <div>
                      <Icon path={mdiInformationOutline} size={1} color="white" />
                    </div>
                  </div>
                </div>
              } else if (isBooked) {
                return <div className={[
                  'fixed',
                  'left-0',
                  'bottom-[-0.5px]',
                  'w-full',
                  'lg:static',
                  'flex',
                  'flex-col',
                  'gap-2',
                  'p-[0.75rem]',
                  'lg:p-4',
                  'z-50',
                  'lg:z-0',
                  'rounded-lg',
                  'rounded-b-none',
                  'lg:rounded-lg',
                  'bg-[white]',
                  'shadow-md',
                  'text-base',
                ].join(' ')}>
                  <div className="peer border-b-2 border-black flex flex-row lg:flex-row gap-1 pb-2">
                    <div className="lg:flex-[0.6_1_0%] flex flex-row items-center">
                      {lexicon.amount}
                    </div>
                    <div className="flex-1 flex flex-row gap-1">
                      <div className="flex-1 flex flex-row font-medium rounded-md border-2 border-black">
                        <button
                          className={[
                            'aspect-square',
                            'p-1',
                            'flex',
                            'justify-center',
                            'items-center',
                            'text-xl',
                            'disabled:bg-gray-100',
                            'disabled:cursor-not-allowed',
                            'rounded-l-md',
                          ].join(' ')}
                          disabled={true}
                          onClick={() => setValidAmount(amount - 1000)}
                        >
                          -
                        </button>
                        <InputNumber
                          disabled={true}
                          defaultValue={bookedAmount}
                          min={1000}
                          max={listedProject.potentialOffset}
                          value={bookedAmount}
                          controls={false}
                          className={[
                            'p-1',
                            'm-0',
                            'w-full',
                            'text-center',
                            'border-x-2',
                            'border-black',
                            'border-t-0',
                            'border-b-0',
                            'rounded-none',
                          ].join(' ')}
                        />
                        <button
                          className={[
                            'aspect-square',
                            'p-1',
                            'flex',
                            'justify-center',
                            'items-center',
                            'text-xl',
                            'disabled:bg-gray-100',
                            'disabled:cursor-not-allowed',
                            'rounded-r-md',
                          ].join(' ')}
                          disabled={true}
                          onClick={() => setValidAmount(amount + 1000)}
                        >
                          +
                        </button>
                      </div>
                      <div className="text-sm flex items-center">tCO<sub>2</sub>e</div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 text-sm">
                    <div className="flex flex-row justify-between">
                      <div>
                        {lexicon.available_stock}
                      </div>
                      <div>
                        {listedProject.potentialOffset.toLocaleString()} tCO<sub>2</sub>e
                      </div>
                    </div>
                    <div className="flex flex-row justify-between">
                      <div>
                        {lexicon.minimum_purchase}
                      </div>
                      <div>
                        1000 tCO<sub>2</sub>e
                      </div>
                    </div>
                  </div>
                  <button
                    disabled={
                      (isOwned !== 'waiting' && isOwned) ||
                      (bookedAmount !== 'waiting' && bookedAmount)
                    }
                    className={[
                      'bg-[#3EB6AE]',
                      'px-4',
                      'py-2',
                      'rounded-md',
                      'flex',
                      'flex-row',
                      'justify-between',
                      'items-center',
                      'disabled:cursor-not-allowed',
                      'disabled:bg-gray-400',
                    ].join(' ')}
                    onClick={() => {
                      handler.requestProjectBooking({
                        projectId: listedProject.id,
                        amount,
                      })
                    }}
                  >
                    <div className="font-semibold text-white text-sm">
                      {lexicon.credits_booked}
                    </div>
                    <div>
                      <Icon path={mdiInformationOutline} size={1} color="white" />
                    </div>
                  </button>
                </div>
              } else if (isFunded) {
                return <div className={[
                  'fixed',
                  'left-0',
                  'bottom-[-0.5px]',
                  'w-full',
                  'lg:static',
                  'flex',
                  'flex-col',
                  'gap-2',
                  'p-[0.75rem]',
                  'lg:p-4',
                  'z-50',
                  'lg:z-0',
                  'rounded-lg',
                  'rounded-b-none',
                  'lg:rounded-lg',
                  'bg-[white]',
                  'shadow-md',
                  'text-base',
                ].join(' ')}>
                  <div className={[
                    'px-4',
                    'py-2',
                    'rounded-md',
                    'flex',
                    'flex-row',
                    'justify-between',
                    'items-center',
                    'bg-gray-400',
                  ].join(' ')}>
                    <div className="font-semibold text-white text-sm">
                      {lexicon.youve_requested_to_fund_this_project}
                    </div>
                    <div>
                      <Icon path={mdiInformationOutline} size={1} color="white" />
                    </div>
                  </div>
                </div>
              } else if (!listedProject.potentialOffset) {
                return <div className={[
                  'fixed',
                  'left-0',
                  'bottom-[-0.5px]',
                  'w-full',
                  'lg:static',
                  'flex',
                  'flex-col',
                  'gap-2',
                  'p-[0.75rem]',
                  'lg:p-4',
                  'z-50',
                  'lg:z-0',
                  'rounded-lg',
                  'rounded-b-none',
                  'lg:rounded-lg',
                  'bg-[white]',
                  'shadow-md',
                  'text-base',
                ].join(' ')}>
                  <button
                    disabled={false}
                    className={[
                      'bg-[#C7ECE7]',
                      'px-4',
                      'py-2',
                      'rounded-md',
                      'flex',
                      'flex-row',
                      'justify-between',
                      'items-center',
                      'disabled:cursor-not-allowed',
                      'disabled:bg-gray-400',
                      'text-[#4C638C]',
                      'disabled:text-white',
                      'border-[1px]',
                      'disabled:border-none',
                      'border-[#3EB6AE]',
                    ].join(' ')}
                    onClick={() => {
                      handler.requestProjectFunding({
                        projectId: listedProject.id,
                      })
                    }}
                  >
                    <div className="font-semibold  text-sm">
                      Fund Project
                    </div>
                    <div>
                      <ExchangeFundsLineIcon size={'1.5rem'} color="#4C638C" />
                    </div>
                  </button>
                </div>
              } else {
                return <div className={[
                  'fixed',
                  'left-0',
                  'bottom-[-0.5px]',
                  'w-full',
                  'lg:static',
                  'flex',
                  'flex-col',
                  'gap-2',
                  'p-[0.75rem]',
                  'lg:p-4',
                  'z-50',
                  'lg:z-0',
                  'rounded-lg',
                  'rounded-b-none',
                  'lg:rounded-lg',
                  'bg-[white]',
                  'shadow-md',
                  'text-base',
                ].join(' ')}>
                  <div className="peer border-b-2 border-black flex flex-row lg:flex-row gap-1 pb-2">
                    <div className="lg:flex-[0.6_1_0%] flex flex-row items-center">
                      {lexicon.amount}
                    </div>
                    <div className="flex-1 flex flex-row gap-1">
                      <div className="flex-1 flex flex-row font-medium rounded-md border-2 border-black">
                        <button
                          className={[
                            'aspect-square',
                            'p-1',
                            'flex',
                            'justify-center',
                            'items-center',
                            'text-xl',
                            'disabled:bg-gray-100',
                            'disabled:cursor-not-allowed',
                            'rounded-l-md',
                          ].join(' ')}
                          disabled={false}
                          onClick={() => setValidAmount(amount - 1000)}
                        >
                          -
                        </button>
                        <InputNumber
                          disabled={false}
                          defaultValue={amount}
                          min={1000}
                          max={listedProject.potentialOffset}
                          value={amount}
                          controls={false}
                          formatter={(value) => numberFormatter.format(value)}
                          parser={(value) => numberParser.parse(value)}
                          onChange={setValidAmount}
                          className={[
                            'p-1',
                            'm-0',
                            'w-full',
                            'text-center',
                            'border-x-2',
                            'border-black',
                            'border-t-0',
                            'border-b-0',
                            'rounded-none',
                          ].join(' ')}
                        />
                        <button
                          className={[
                            'aspect-square',
                            'p-1',
                            'flex',
                            'justify-center',
                            'items-center',
                            'text-xl',
                            'disabled:bg-gray-100',
                            'disabled:cursor-not-allowed',
                            'rounded-r-md',
                          ].join(' ')}
                          disabled={false}
                          onClick={() => setValidAmount(amount + 1000)}
                        >
                          +
                        </button>
                      </div>
                      <div className="text-sm flex items-center">tCO<sub>2</sub>e</div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 text-sm">
                    <div className="flex flex-row justify-between">
                      <div>
                        Available Stock
                      </div>
                      <div>
                        {listedProject.potentialOffset.toLocaleString()} tCO<sub>2</sub>e
                      </div>
                    </div>
                    <div className="flex flex-row justify-between">
                      <div>
                        Minimum Purchase
                      </div>
                      <div>
                        1000 tCO<sub>2</sub>e
                      </div>
                    </div>
                  </div>
                  <button
                    disabled={false}
                    className={[
                      'bg-[#3EB6AE]',
                      'px-4',
                      'py-2',
                      'rounded-md',
                      'flex',
                      'flex-row',
                      'justify-between',
                      'items-center',
                      'disabled:cursor-not-allowed',
                      'disabled:bg-gray-400',
                    ].join(' ')}
                    onClick={() => {
                      handler.requestProjectBooking({
                        projectId: listedProject.id,
                        amount,
                      })
                    }}
                  >
                    <div className="font-semibold text-white text-sm">
                      {lexicon.book_credits}
                    </div>
                    <div>
                      <Icon path={mdiPlusBoxOutline} size={1} color="white" />
                    </div>
                  </button>
                  <button
                    disabled={false}
                    className={[
                      'bg-[#C7ECE7]',
                      'px-4',
                      'py-2',
                      'rounded-md',
                      'flex',
                      'flex-row',
                      'justify-between',
                      'items-center',
                      'disabled:cursor-not-allowed',
                      'disabled:bg-gray-400',
                      'text-[#4C638C]',
                      'disabled:text-white',
                      'border-[1px]',
                      'disabled:border-none',
                      'border-[#3EB6AE]',
                    ].join(' ')}
                    onClick={() => {
                      handler.requestProjectFunding({
                        projectId: listedProject.id,
                      })
                    }}
                  >
                    <div className="font-semibold  text-sm">
                      {lexicon.fund_project}
                    </div>
                    <div>
                      <ExchangeFundsLineIcon size={'1.5rem'} color="#4C638C" />
                    </div>
                  </button>
                </div>
              }
            })()}
          </div>
        </div>
      </div>
    </Layout>
  }
}

const numberFormatter = new d2lIntl.NumberFormat()
const numberParser = new d2lIntl.NumberParse()

const sdgs = [
  'No Poverty',
  'Zero Hunger',
  'Good Health And Well-Being',
  'Quality Education',
  'Gender Equality',
  'Clean Water And Sanitation',
  'Affordable And Clean Energy',
  'Decent Work And Economic Growth',
  'Industry, Innovation And Infrastructure',
  'Reduced Inequalities',
  'Sustainable Cities And Communities',
  'Responsible Consumption And Production',
  'Climate Action',
  'Life Below Water',
  'Life On Land',
  'Peace, Justice And Strong Institutions',
  'Partnerships For The Goals',
]

export default ListedProject