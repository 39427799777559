import { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useHandler } from "./handler_provider"
import Layout from "./layout";
import {
  BlowerIcon,
  FileIcon,
  FileWarningIcon,
  FileDoneIcon,
} from './project_submission_icons.js'

function ProjectSubmission(props) {
  const handler = useHandler()
  const [lexicon, setLexicon] = useState({})
  useEffect(() => {
    handler.subToLexicon(setLexicon)
    return () => handler.unsubFromLexicon(setLexicon)
  }, [handler])
  const [graph, setGraph] = useState({})
  useEffect(() => {
    handler.subToGraph(setGraph)
    return () => handler.unsubFromGraph(setGraph)
  }, [handler])
  const [questions, setQuestions] = useState(['what_is_the_project_sector'])
  const [answers, setAnswers] = useState([])
  const [selectedAnswer, setSelectedAnswer] = useState('')
  const answered = !graph[questions[questions.length - 1]]
  const [projectSubmissionResult, setProjectSubmissionResult] = useState(null)
  useEffect(() => {
    const setValidProjectSubmissionResult = (result) => {
      result && setProjectSubmissionResult(result)
    }
    handler.subToProjectSubmissionResult(setValidProjectSubmissionResult)
    return () => {
      handler.unsubFromProjectSubmissionResult(setValidProjectSubmissionResult)
    }
  }, [handler])
  const [reviewed, setReviewed] = useState(false)
  const phase = !answered
    ? 0 : !reviewed
      ? 1
      : questions[questions.length - 1] !== 'afolu'
        ? 3
        : !projectSubmissionResult
          ? 2
          : 3
  const back = useCallback(() => {
    if (reviewed) {
      setReviewed(false)
    } else {
      setAnswers(answers.slice(0, -1))
      setQuestions(questions.slice(0, -1))
      setSelectedAnswer(answers[answers.length - 1])
    }
  }, [questions, answers, reviewed])
  const next = useCallback(() => {
    if (selectedAnswer) {
      const possibleAnswers = graph[questions[questions.length - 1]].options
      const nextQuestion = possibleAnswers[selectedAnswer].next
      setAnswers([...answers, selectedAnswer])
      setQuestions([...questions, nextQuestion])
      setSelectedAnswer('')
    }
  }, [graph, questions, answers, selectedAnswer])

  return <Layout>
    <div className="flex flex-col min-h-screen font-sans">
      <div className="flex flex-col lg:flex-row-reverse p-8 gap-3">
        <div className="lg:flex-[1_1_0%] flex flex-col gap-2">
          <div
            className={[
              'border-2',
              'rounded-lg',
              'p-3',
              'bg-[#4C638C]',
              'border-[#C7ECE7]',
              'text-[#C7ECE7]',
              'font-semibold',
              phase !== 0 ? 'hidden' : 'flex',
              'lg:flex',
              'flex-row',
              'justify-between',
            ].join(' ')}
          >
            <div>{lexicon.answer_questions}</div>
            <FileIcon />
          </div>
          <div
            className={[
              'border-2',
              'rounded-lg',
              'p-3',
              'font-semibold',
              phase < 1 ? 'bg-[#F3F3F9]' : 'bg-[#4C638C]',
              phase < 1 ? 'border-[#4C638C]' : 'border-[#C7ECE7]',
              phase < 1 ? 'text-[#4C638C]' : 'text-[#C7ECE7]',
              phase !== 1 ? 'hidden' : 'flex',
              'lg:flex',
              'flex-row',
              'justify-between',
            ].join(' ')}
          >
            <div>{lexicon.review_answers}</div>
            <FileWarningIcon alternate={phase < 1} />
          </div>
          <div
            className={[
              'border-2',
              'rounded-lg',
              'p-3',
              'font-semibold',
              phase < 2 ? 'bg-[#F3F3F9]' : 'bg-[#4C638C]',
              phase < 2 ? 'border-[#4C638C]' : 'border-[#C7ECE7]',
              phase < 2 ? 'text-[#4C638C]' : 'text-[#C7ECE7]',
              phase !== 2 ? 'hidden' : 'flex',
              'lg:flex',
              'flex-row',
              'justify-between',
            ].join(' ')}
          >
            <div>{lexicon.project_details}</div>
            <FileIcon alternate={phase < 2} />
          </div>
          <div
            className={[
              'border-2',
              'rounded-lg',
              'p-3',
              'font-semibold',
              phase < 3 ? 'bg-[#F3F3F9]' : 'bg-[#4C638C]',
              phase < 3 ? 'border-[#4C638C]' : 'border-[#C7ECE7]',
              phase < 3 ? 'text-[#4C638C]' : 'text-[#C7ECE7]',
              phase !== 3 ? 'hidden' : 'flex',
              'lg:flex',
              'flex-row',
              'justify-between',
            ].join(' ')}
          >
            <div>{lexicon.view_result}</div>
            <FileDoneIcon alternate={phase < 3} />
          </div>
        </div>
        <div className="flex-[2.5_1_0%] flex flex-col shadow-md rounded-lg border bg-white">
          {(() => {
            if (!answered) {
              const question = questions[questions.length - 1]
              const label = graph[question].label
              const possibilities = Object.entries(graph[question].options)

              return <div className="flex-1 flex flex-col gap-2 p-3 items-stretch">
                <div className="flex flex-col items-stretch gap-3">
                  <div className="font-semibold">{label}?</div>
                  <div className="flex-1 flex flex-col items-stretch gap-2">
                    {possibilities.map(([answer, { label }]) => {
                      return <div className="flex-1 flex" key={answer}>
                        <button className={[
                          'flex-1',
                          'text-left',
                          'rounded-md',
                          'p-2',
                          'border',
                          'hover:border-slate-300',
                          'hover:bg-slate-300',
                          selectedAnswer === answer ? 'bg-slate-300' : '',
                        ].join(' ')} onClick={() => {
                          setSelectedAnswer(answer)
                        }}>
                          {label}
                        </button>
                      </div>
                    })}
                  </div>
                </div>
                <div className="flex-1"></div>
                <div className="flex flex-row justify-between">
                  {(() => {
                    if (questions.length <= 1) {
                      return <div></div>
                    } else {
                      return <button className={[
                        'text-[#3EB6AE]',
                        'border-2',
                        'border-[#8ACFC2]',
                        'p-3',
                        'rounded-md',
                      ].join(' ')} onClick={back}>
                        {lexicon.back}
                      </button>
                    }
                  })()}

                  {(() => {
                    return <button disabled={!selectedAnswer} className={[
                      'text-white',
                      'bg-[#0BB49C]',
                      'p-3',
                      'rounded-md',
                      'border-2',
                      'disabled:bg-[#CCCCCC]',
                      'disabled:text-[#666666]',
                      'disabled:border-[#999999]',
                    ].join(' ')} onClick={next}>
                      {lexicon.next}
                    </button>
                  })()}
                </div>
              </div>
            } else if (!reviewed) {
              return <div className="flex-1 flex flex-col gap-2 p-3 items-stretch">
                <div className="font-semibold">
                  {lexicon.please_check_your_previous_answers}.
                </div>
                <div className="flex flex-col gap-2">
                  {questions.slice(0, -1).map((question, index) => {
                    const questionLabel = graph[question].label
                    const answerLabel = graph[question]
                      .options[answers.at(index)].label
                    return <div key={question} className={[
                      'flex-1',
                      'flex',
                      'flex-col',
                      'text-left',
                      'border-2',
                      'rounded-md',
                      'p-2',
                      'border-slate-300',
                    ].join(' ')}>
                      <div className="font-semibold">
                        {questionLabel}?
                      </div>
                      <div>
                        {answerLabel}
                      </div>
                    </div>
                  })}
                </div>
                <div className="flex-1"></div>
                <div className="flex flex-row justify-between">
                  <button className={[
                    'text-[#3EB6AE]',
                    'border-2',
                    'border-[#8ACFC2]',
                    'p-3',
                    'rounded-md',
                  ].join(' ')} onClick={back}>
                    {lexicon.back}
                  </button>

                  {(() => {
                    return <button className={[
                      'text-white',
                      'bg-[#0BB49C]',
                      'p-3',
                      'rounded-md',
                      'border-2',
                      'disabled:bg-[#CCCCCC]',
                      'disabled:text-[#666666]',
                      'disabled:border-[#999999]',
                    ].join(' ')} onClick={() => {
                      if (questions[questions.length - 1] !== 'afolu') {
                        const entries = questions.slice(0, -1)
                          .map((question, index) => {
                            return [question, answers.at(index)]
                          })
                        handler.requestQuestionnaireSubmission({
                          content: Object.fromEntries(entries),
                        })
                      }

                      return setReviewed(true)
                    }}>
                      {lexicon.next}
                    </button>
                  })()}
                </div>
              </div>
            } else if (questions[questions.length - 1] !== 'afolu') {
              return <div className="flex-1 flex flex-col gap-4 p-6 items-stretch">
                <div className="text-4xl font-semibold text-[#3EB6AE]">
                  {lexicon.thank_you}!
                </div>
                <div className="whitespace-pre-line">
                  {lexicon.your_project_requires_more_discussion_with_our_consultants_we_ll_be_in_touch_to_schedule_a_consultation}
                </div>
                <div>
                  <Link to="/">
                    <button
                      className={[
                        'text-white',
                        'bg-[#0BB49C]',
                        'p-3',
                        'rounded-md',
                        'border-2',
                        'disabled:bg-[#CCCCCC]',
                        'disabled:text-[#666666]',
                        'disabled:border-[#999999]',
                      ].join(' ')}
                    >
                      {lexicon.back_to_home}
                    </button>
                  </Link>
                </div>
              </div>
            } else if (!projectSubmissionResult) {
              return <div className="flex-1 flex flex-col gap-2 p-3 items-stretch">
                <form
                  className="flex-1 flex flex-col gap-3 items-stretch"
                  onSubmit={(e) => {
                    e.preventDefault()
                    {
                      const entries = questions.slice(0, -1)
                        .map((question, index) => {
                          return [question, answers.at(index)]
                        })
                      handler.requestQuestionnaireSubmission({
                        content: Object.fromEntries(entries),
                      })
                    }

                    const entries = Object.entries(e.target.elements)
                      .filter(([key]) => isNaN(parseInt(key)))
                      .map(([key, value]) => {
                        value = value.value
                        value = key === 'area' ? parseInt(value) : value
                        return [key, value]
                      })
                    handler.requestProjectSubmission({
                      ...Object.fromEntries(entries),
                      type: 'afolu',
                    })
                  }}
                >
                  <div>
                    <label htmlFor="name" className="text-sm font-medium text-gray-900 m-0">
                      Project Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="A Name That Represents Your Project"
                      className={[
                        "bg-gray-50",
                        "border",
                        "border-gray-300",
                        "text-gray-900",
                        "text-sm",
                        "rounded-lg",
                        "focus:ring-blue-500",
                        "focus:border-blue-500",
                        "block",
                        "w-full",
                        "p-2.5",
                      ].join(' ')}
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="area" className="text-sm font-medium text-gray-900 m-0">
                      Total Area (In Hectare)
                    </label>
                    <input
                      type="number"
                      min={1}
                      id="area"
                      name="area"
                      placeholder="10000"
                      className={[
                        "bg-gray-50",
                        "border",
                        "border-gray-300",
                        "text-gray-900",
                        "text-sm",
                        "rounded-lg",
                        "focus:ring-blue-500",
                        "focus:border-blue-500",
                        "block",
                        "w-full",
                        "p-2.5",
                      ].join(' ')}
                      required
                    />
                  </div>
                  <div>

                    <label htmlFor="location" className="text-sm font-medium text-gray-900 m-0">Location</label>
                    <div className="relative">
                      <div className={[
                        "absolute",
                        "right-4",
                        "inset-y-4",
                        "pointer-events-none",
                        "border-r-2",
                        "border-b-2",
                        "h-2",
                        "aspect-square",
                        "border-black",
                        "rotate-45"
                      ].join(' ')} />
                      <select
                        id="location"
                        name="location"
                        className={[
                          "bg-gray-50",
                          "border",
                          "border-gray-300",
                          "text-gray-900",
                          "text-sm",
                          "rounded-lg",
                          "focus:ring-blue-500",
                          "focus:border-blue-500",
                          "block",
                          "w-full",
                          "p-2.5",
                          "appearance-none"
                        ].join(' ')}
                        required
                      >
                        <option value="Aceh">Aceh</option>
                        <option value="North Sumatra">North Sumatra</option>
                        <option value="Riau" >Riau </option>
                        <option value="Jambi">Jambi</option>
                        <option value="West Sumatra">West Sumatra</option>
                        <option value="South Sumatra">South Sumatra</option>
                        <option value="Bengkulu">Bengkulu</option>
                        <option value="Lampung">Lampung</option>
                        <option value="Riau Islands">Riau Islands</option>
                        <option value="Bangka-Belitung">Bangka-Belitung</option>
                        <option value="Banten">Banten</option>
                        <option value="DKI Jakarta">DKI Jakarta</option>
                        <option value="West Java">West Java</option>
                        <option value="Central Java">Central Java</option>
                        <option value="Yogyakarta">Yogyakarta</option>
                        <option value="East Java">East Java</option>
                        <option value="Bali">Bali</option>
                        <option value="West Kalimantan">West Kalimantan</option>
                        <option value="Central Kalimantan">Central Kalimantan</option>
                        <option value="South Kalimantan">South Kalimantan</option>
                        <option value="East Kalimantan">East Kalimantan</option>
                        <option value="North Kalimantan">North Kalimantan</option>
                        <option value="North Sulawesi">North Sulawesi</option>
                        <option value="Gorontalo" >Gorontalo</option>
                        <option value="Central Sulawesi">Central Sulawesi</option>
                        <option value="South East Sulawesi">South East Sulawesi</option>
                        <option value="South Sulawesi">South Sulawesi</option>
                        <option value="West Sulawesi">West Sulawesi</option>
                        <option value="Maluku (Ambon)">Maluku (Ambon)</option>
                        <option value="North Maluku">North Maluku</option>
                        <option value="East Nusa">East Nusa</option>
                        <option value="West Nusa">West Nusa</option>
                        <option value="Papua (Jayapura)">Papua (Jayapura)</option >
                        <option value="West Papua">West Papua</option>
                      </select >
                    </div>
                  </div>
                  {/* <div>
                    <label
                      htmlFor="mechanisms"
                      className={[
                        'text-sm',
                        'font-medium',
                        'text-gray-900',
                        'm-0',
                      ].join(' ')}>
                      Mechanisms
                    </label>
                    <div className="flex flex-col lg:flex-row lg:gap-3">
                      <div className="flex flex-row justify-start items-center gap-1">
                        <input
                          type="checkbox"
                          name="mechanisms"
                          value="SPE GRK"
                        />
                        <div>
                          SPE GRK
                        </div>
                      </div>
                      <div className="flex flex-row justify-start items-center gap-1">
                        <input
                          type="checkbox"
                          name="mechanisms"
                          value="Verra"
                        />
                        <div>
                          Verra
                        </div>
                      </div>
                      <div className="flex flex-row justify-start items-center gap-1">
                        <input
                          type="checkbox"
                          name="mechanisms"
                          value="Plan Vivo"
                        />
                        <div>
                          Plan Vivo
                        </div>
                      </div>
                      <div className="flex flex-row justify-start items-center gap-1">
                        <input
                          type="checkbox"
                          id="mechanisms"
                          name="mechanisms"
                          value="Gold Standard"
                        />
                        <div>
                          Gold Standard
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div>
                    <label
                      htmlFor="description"
                      className="text-sm font-medium text-gray-900 m-0"
                    >
                      Description
                    </label>
                    <textarea
                      type="text"
                      id="description"
                      name="description"
                      placeholder="A Brief Description Of Your Project"
                      className={[
                        "resize-none",
                        "bg-gray-50",
                        "border",
                        "border-gray-300",
                        "text-gray-900",
                        "text-sm",
                        "rounded-lg",
                        "focus:ring-blue-500",
                        "focus:border-blue-500",
                        "block",
                        "w-full",
                        "p-2.5",
                        "h-32",
                      ].join(' ')}
                      required
                    />
                  </div>
                  <div className="flex-1"></div>
                  <div className="flex flex-row justify-between">
                    <button className={[
                      'text-[#3EB6AE]',
                      'border-2',
                      'border-[#8ACFC2]',
                      'p-3',
                      'rounded-md',
                    ].join(' ')} onClick={back}>
                      {lexicon.back}
                    </button>

                    {(() => {
                      return <button
                        type="submit"
                        className={[
                          'text-white',
                          'bg-[#0BB49C]',
                          'p-3',
                          'rounded-md',
                          'border-2',
                          'disabled:bg-[#CCCCCC]',
                          'disabled:text-[#666666]',
                          'disabled:border-[#999999]',
                        ].join(' ')}
                      >
                        {lexicon.next}
                      </button>
                    })()}
                  </div>
                </form>
              </div>
            } else {
              return <div className="flex-1 flex flex-col gap-4 p-6 items-stretch">
                <div className="text-4xl font-semibold text-[#3EB6AE] flex flex-row gap-3">
                  <div>
                    {lexicon.congratulation}!
                  </div>
                  <BlowerIcon />
                </div>
                <div>
                  <div className="inline">
                    {lexicon.our_analysis_estimated_that_your_project_can_potentially_reduce} <span className="font-bold text-[#3EB6AE]">
                      {projectSubmissionResult?.potentialOffset?.toLocaleString()} tCO<sub>2</sub>e
                    </span>.&nbsp;
                  </div>
                  <div className="inline">
                    {lexicon.that_s_not_just_good_news_for_the_environment_but_it_s_also_a_significant_opportunity_for_you_to_earn_money_while_making_a_positive_impact}
                  </div>
                </div>
                <div>
                  <Link to="/owned-projects">
                    <button
                      className={[
                        'text-white',
                        'bg-[#0BB49C]',
                        'p-3',
                        'rounded-md',
                        'border-2',
                        'disabled:bg-[#CCCCCC]',
                        'disabled:text-[#666666]',
                        'disabled:border-[#999999]',
                      ].join(' ')}
                    >
                      {lexicon.go_to_projects}
                    </button>
                  </Link>
                </div>
              </div>
            }
          })()}
        </div>
      </div>
    </div >
  </Layout>
}

export default ProjectSubmission