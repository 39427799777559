import { useEffect, useState } from "react";
import Layout from "./layout";
import Icon from "@mdi/react";
import { mdiApproximatelyEqualBox, mdiMapMarkerOutline, mdiNumeric1CircleOutline, mdiNumeric2CircleOutline, mdiWindowClose } from "@mdi/js";
import { Link } from "react-router-dom";
import { useHandler } from "./handler_provider";
import LandingTop from "./landing_top.jpg";
import LandingBottom from "./landing_bottom.jpg";
import ImageHowitworks from "./how_it_works.jpg";
import { Modal, Select } from "antd";

function ListedProjects() {
  const handler = useHandler()
  const [listedProjectSummaries, setListedProjectSummaries] = useState([])
  const [sortBy, setSortBy] = useState('-id')
  useEffect(() => {
    handler.requestListedProjectSummariesLookup({ sortBy })
    handler.subToListedProjectSummaries(setListedProjectSummaries)
    return () => {
      handler.unsubFromListedProjectSummaries(setListedProjectSummaries)
    }
  }, [handler, sortBy])
  useEffect(() => {
    const navbar = document.getElementById('navbar')
    const subNavbar = document.getElementById('sub-navbar')
    const observer = new ResizeObserver(([navbar]) => {
      requestAnimationFrame(() => {
        subNavbar.style.top = `${navbar.target.offsetHeight - 0.9}px`
      })
    })
    observer.observe(navbar)
    return () => observer.unobserve(navbar)
  }, [])
  useEffect(() => {
    document.body.classList.add('[&_.ant-select-item-option-content]:font-sans')
    document.body.classList.add('[&_.ant-select-item-option-content]:font-semibold')
    document.body.classList.add('[&_.ant-select-item-option-content]:text-base')
    return () => {
      document.body.classList.remove('[&_.ant-select-item-option-content]:font-sans')
      document.body.classList.remove('[&_.ant-select-item-option-content]:font-semibold')
      document.body.classList.remove('[&_.ant-select-item-option-content]:text-base')
    }
  }, [])
  const [lexicon, setLexicon] = useState({})
  useEffect(() => {
    handler.subToLexicon(setLexicon)
    return () => handler.unsubFromLexicon(setLexicon)
  }, [handler])
  const [howItWorks, setHowItWorks] = useState(false)

  return <Layout>
    <div className="relative">
      <div
        className={[
          'text-xl',
          'bg-slate-300',
          'text-white',
          'p-11',
          'md:p-20',
          'flex',
          'flex-col',
          'gap-3',
          'items-start',
        ].join(' ')}
        style={{
          backgroundImage: `url(${LandingTop})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className="font-semibold text-4xl md:text-5xl whitespace-pre-line">
          {lexicon.offset_your_carbon_footprint_with_trucarbon}
        </div>
        <button
          className="bg-[#3EB6AE] rounded-md px-4 py-3"
          onClick={() => { setHowItWorks(true) }}
        >
          {lexicon.how_it_works}
        </button>
        <Modal
          open={howItWorks}
          closable={false}
          onCancel={() => setHowItWorks(false)}
          className="[&_.ant-modal-content]:p-0"
          title={null}
          footer={null}
          bodyStyle={{ maxWidth: '100%' }}
        >
          <div className="flex flex-col">
            <div className="border-b-2 border-[#4C638C] rounded-t-lg">
              <div className="text-base font-semibold flex justify-between items-center px-4 py-2">
                <div className="text-[#4C638C]">
                  {lexicon.how_truclimate_works}
                </div>
                <button onClick={() => setHowItWorks(false)}>
                  <Icon
                    path={mdiWindowClose}
                    size={0.9}
                  />
                </button>
              </div>
            </div>
            <div className="px-4 py-2 rounded-b-lg flex flex-col gap-3">
              <div className="h-auto bg-[#4C638C] rounded-lg">
                <img src={ImageHowitworks} alt="How it works" />
              </div>
              <div className="flex flex-col md:flex-row gap-3 md:gap-9">
                <div className="flex-1 flex flex-col">
                  <div className="flex gap-1 py-2">
                    <Icon path={mdiNumeric1CircleOutline} size={1.3} className="inline" />
                    <div className="flex text-lg font-semibold ">
                      {lexicon.prebook_project}
                    </div>
                  </div>
                  <div className="text-[#4C638C]">
                    {lexicon.prebook_project_description}
                  </div>
                </div>
                <div className="flex-1 flex flex-col">
                  <div className="flex gap-1 py-2">
                    <Icon path={mdiNumeric2CircleOutline} size={1.3} className="inline" />
                    <div className="flex text-lg font-semibold ">
                      {lexicon.direct_order}
                    </div>
                  </div>
                  <div className="text-[#4C638C]">
                    {lexicon.direct_order_description}
                  </div>
                </div>
              </div>
              <div className="flex flex-col pb-4">
                <div className="text-lg font-semibold">
                  {lexicon.didnt_find_any_projects}?
                </div>
                <div className="text-[#4C638C]">
                  {lexicon.didnt_find_any_projects_description}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
    <div
      id="sub-navbar"
      className={[
        'flex',
        'flex-col',
        'lg:flex-row',
        'gap-2',
        'px-11',
        'md:px-14',
        'py-6',
        'sticky',
        'bg-white',
        'z-10',
      ].join(' ')}
    >
      <div className={[
        'font-semibold',
        'text-2xl',
        'text-black',
        'flex',
        'flex-row',
        'items-center',
      ].join(' ')}>
        <div>
          {listedProjectSummaries.length ?? 0} {lexicon.carbon_projects_available}
        </div>
      </div>
      <div className="flex-1"></div>
      <div className={[
        'flex',
        'flex-row',
        'gap-2',
        'bg-white',
        'items-center',
        'text-base',
        (
          !listedProjectSummaries ||
          !listedProjectSummaries.length
        ) ? 'hidden' : '',
      ].join(' ')}>
        <div>
          {lexicon.sort_by}:
        </div>
        <div className={[
          '[&_.ant-select-selection-item]:font-sans',
          '[&_.ant-select-selection-item]:font-semibold',
          '[&_.ant-select-selection-item]:text-base',
          '[&_.ant-select-arrow]:text-sm',
        ].join(' ')}>
          <Select
            value={sortBy}
            options={[
              { value: '-id', label: lexicon.latest },
              { value: 'id', label: lexicon.earliest },
            ]}
            onChange={setSortBy}
          />
        </div>
      </div>
    </div>
    <div className={[
      'min-h-screen',
      'flex',
      'flex-col',
      'font-sans',
      'bg-[#F3F3F9]',
    ].join(' ')}>
      {
        !listedProjectSummaries || !listedProjectSummaries.length
          ? <div className="flex justify-center items-center flex-1 text-3xl">
            🍀
          </div>
          : <div className={[
            'grid',
            'grid-cols-1',
            'md:grid-cols-2',
            'lg:grid-cols-3',
            'gap-4',
            'px-4',
            'lg:px-14',
            'py-8',
          ].join(' ')}>
            {listedProjectSummaries.map((summary) => {
              return <div key={summary.id} className={[
                'bg-white',
                'border-t border-l border-r',
                'border-b-8',
                'shadow-md',
                'rounded-lg',
                'flex',
                'flex-col',
                'gap-3',
                'p-3',
                'transition-all',
                'hover:-translate-y-2',
                'hover:shadow-xl',
                'hover:shadow-[0px_9px_#3EB6AE]',
              ].join(' ')}>
                <div className="bg-slate-300 aspect-[1/0.7] rounded-md flex flex-row">
                  {summary.image && <img
                    alt=""
                    src={`data:image/jpeg;base64,${summary.image}`}
                    className="flex-1 rounded-md object-cover"
                  />}
                </div>
                <div className="flex flex-row gap-2">
                  <div className={[
                    'bg-[#C7ECE7]',
                    'text-[#3EB6AE]',
                    'border',
                    'border-[#3EB6AE]',
                    'rounded-md',
                    'px-3',
                    'py-1',
                  ].join(' ')}>
                    {lexicon.carbon_credit}
                  </div>
                  {/* <div className={[
                    'bg-[#F3F3F9]',
                    'text-[#4C638C]',
                    'border',
                    'border-[#4C638C]',
                    'rounded-md',
                    'px-3',
                    'py-1',
                  ].join(' ')}>
                    Verra
                  </div> */}
                </div>
                <div className="group relative">
                  <div id={`name-${summary.id}`} title={summary.name} className={[
                    'text-xl',
                    'font-semibold',
                    'whitespace-nowrap',
                    'overflow-hidden',
                    'text-ellipsis',
                  ].join(' ')}>
                    {summary.name}
                  </div>
                  {/* <div className={[
                    'scale-0',
                    'group-hover:scale-100',
                    'first:scale-0',
                    'group-hover:first:scale-100',
                    'opacity-[100]',
                    'group-hover:opacity-100',
                    'flex',
                    'flex-col',
                    'justify-center',
                    'items-center',
                    'absolute',
                    'left-0',
                    'top-[111%]',
                    'delay-1000',
                    'transition-all',
                    'px-3',
                    'py-2',
                    'rounded-md',
                    'bg-gray-700',
                    'text-white',
                    'border-none',
                  ].join(' ')}>
                    <div className={[
                      'absolute',
                      'bottom-full',
                      'border-8',
                      'bg-transparent',
                      'border-l-transparent',
                      'border-r-transparent',
                      'border-t-transparent',
                      'border-b-gray-700',
                      'h-0',
                      'w-0',
                    ].join(' ')} />
                    {summary.name}
                  </div> */}
                </div>
                <div className={[
                  'text-sm',
                  'line-clamp-4',
                ].join(' ')}>
                  {summary.description}
                </div>
                <div className="flex-1"></div>
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row items-center">
                    <Icon path={mdiMapMarkerOutline} size={1.4} />
                    <div>
                      {summary.location}
                    </div>
                  </div>
                  {
                    !summary.potentialOffset
                      ? <></>
                      : <div className="flex flex-row justify-start items-center">
                        <Icon path={mdiApproximatelyEqualBox} size={1.4} />
                        <div>
                          {summary.potentialOffset.toLocaleString()} tCO<sub>2</sub>e
                        </div>
                      </div>
                  }
                </div>
                <Link to={`/listed-projects/${summary.id}`} className={[
                  'bg-[#3EB6AE]',
                  'text-white',
                  'flex',
                  'flex-row',
                  'justify-center',
                  'items-center',
                  'rounded-md',
                  'py-1',
                  'h-9',
                  'font-medium',
                ].join(' ')}>
                  {lexicon.see_details}
                </Link>
              </div>
            })}
          </div>
      }
      <div className="relative">
        <div
          className={[
            'text-xl',
            'bg-slate-300',
            'text-white',
            'p-11',
            'md:p-20',
            'flex',
            'flex-col',
            'gap-3',
            'items-start',
          ].join(' ')}
          style={{
            backgroundImage: `url(${LandingBottom})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <div className="font-semibold text-xl">
            {lexicon.for_carbon_project_developer}
          </div>
          <div className="font-semibold text-4xl md:text-5xl whitespace-pre-line">
            {lexicon.join_a_group_of_leading_carbon_project_developer_partners}
          </div>
          <Link
            to='/eligibility-check-submission/owned'
            className="bg-[#3EB6AE] rounded-md px-4 py-3 hover:text-white"
          >
            {lexicon.submit_eligibility_check}
          </Link>
        </div>
      </div>
    </div>
  </Layout>
}

export default ListedProjects